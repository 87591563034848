import { Box } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import theme from "theme/theme";

const ChartOptions: ApexOptions = {
  labels: ["A.P.T.", "Operatore", "Adobe"],
  colors: [
    theme.colors.yellow[500],
    theme.colors.cyan[500],
    theme.colors.gray[500],
  ],

  chart: {
    width: "180px",
    height: "180px",
    offsetY: 0,
    offsetX: 5,
  },
  /* states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  }, */
  legend: {
    show: false,
  },

  dataLabels: {
    enabled: true,
    style: {
      fontFamily: "DM Sans",
      colors: ["#010101", "#010101", "#010101"],
    },
    dropShadow: {
      enabled: false,
    },
  },

  //hover: { mode: null },
  plotOptions: {
    pie: {
      expandOnClick: false,
      dataLabels: {
        offset: 30,
      },
      donut: {
        labels: {
          show: true,
          value: {
            show: true,
            fontFamily: "DM Sans",
            color: "#666",
          },
          name: {
            show: true,
            fontFamily: "DM Sans",
            color: "#666",
          },
          /* total: {
            show: true,
            //showAlways: true,
            fontFamily: "DM Sans",
            color: "#666",
          }, */
        },
      },
    },
  },
  grid: {
    padding: {
      left: 30,
      right: 30,
      bottom: 30,
      top: 30,
    },
  },
  fill: {
    colors: [
      theme.colors.red[500],
      theme.colors.blue[500],
      theme.colors.gray[500],
    ],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

type ManagedChartProps = {
  series: number[];
};

const ManagedChart = (props: ManagedChartProps) => {
  return (
    <ReactApexChart
      options={ChartOptions}
      series={props.series}
      type="donut"
      width="180px"
      height="180px"
    />
  );
};

export { ManagedChart };
