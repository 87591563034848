import React from "react";
import { Moment } from "moment";

interface DayProps {
  classes?: string;
  dayClicked: (day: Moment) => void;
  dayHovered: (day: Moment) => void;
  day: Moment | null;
  title?: string;
}

const Day: React.FC<DayProps> = ({
  classes = "",
  day,
  title,
  dayClicked,
  dayHovered,
}) => {
  const onClick = () => {
    if (day !== null) {
      dayClicked(day);
    }
  };

  const onHover = () => {
    if (day !== null) {
      dayHovered(day);
    }
  };

  return (
    <td
      onClick={onClick}
      onMouseEnter={onHover}
      className={classes}
      title={title}
    >
      <span className="day-number">{day === null ? "" : day.date()}</span>
    </td>
  );
};

export default Day;
