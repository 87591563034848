import {
  Box,
  Flex,
  FlexProps,
  Spinner,
  useStyleConfig,
} from "@chakra-ui/react";

type LoadingProps = {
  trasparent?: boolean;
};

const Loading = (props: LoadingProps) => {
  return (
    <Flex
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      bg={props.trasparent ? "undefined" : "whiteAlpha.500"}
      zIndex={99}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
      />
    </Flex>
  );
};

export { Loading };
