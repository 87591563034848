import QRCode from "react-qr-code";

import React, { useEffect, useState } from "react";

import {
  Flex,
  Text,
  VStack,
  Box,
  Switch,
  Button,
  Input,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import Card from "ui/Card";
import { CalendarIcon, CheckIcon, EmailIcon, TimeIcon } from "@chakra-ui/icons";
import { Loading } from "ui/Loading/Loading";
import {
  AREA,
  AREA_GENERATE_INFOPOINTCODE,
  ApolloMutation,
  Resource,
} from "stores/queries/area";
import { useMutation, useQuery } from "@apollo/client";
import { ACTUAL_AUTH } from "stores/db/auth";

const downloadPDF = (pdf: string, name: string) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = "QR-TM_" + name + ".pdf";

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

type GetByIdMutation = ({
  variables,
}: {
  variables: {
    _id: string;
  };
}) => Promise<any>;
type GetByIdsMutation = ({
  variables,
}: {
  variables: {
    _ids: string | string[];
  };
}) => Promise<any>;
type SecureManageMutation = ({
  variables,
}: {
  variables: {
    _ids: string | string[];
    secure: {
      reNewCode: boolean;
      permissions: {
        manual: boolean;
        calendar: boolean;
      };
    };
  };
}) => Promise<any>;

interface InfoPointLinkProps {
  a?: any;

  /*   _id: string;
  resource: Resource;
  type: "track" | "poi" | "transport";
  secureManage: SecureManageMutation;
  getQrPdf: GetByIdMutation;
  getQrEmail: GetByIdsMutation;
  loading: boolean; */
}

const InfoPointLink = (props: InfoPointLinkProps) => {
  const [url, setUrl] = useState("");
  const toast = useToast();

  const { loading, data } = useQuery(AREA, {
    variables: { shortName: ACTUAL_AUTH.area },
    fetchPolicy: "no-cache",
    skip: !ACTUAL_AUTH.area,
  });

  const [generateCode, { loading: rcLoading }] = useMutation(
    AREA_GENERATE_INFOPOINTCODE,
    {
      refetchQueries: [AREA],
    }
  );

  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure();

  useEffect(() => {
    console.log(data?.adminArea);
    if (data?.adminArea?.infoPointCode) {
      setUrl(
        window.location.origin +
          `/?si=${data.adminArea.infoPointCode}&a=${ACTUAL_AUTH.area}`
      );
    }
  }, [data?.adminArea]);

  return (
    <Card
      w="100%"
      h="100%"
      align={{ base: "center", xl: "center" }}
      justify={{ base: "center", xl: "center" }}
    >
      <Flex flex={1} flexDirection={"row"}>
        <Flex flex={1} flexDirection="column" p={2}>
          <Text fontSize="3xl" color="gray.800" fontWeight={"bold"}>
            Link Info Point
          </Text>

          <Text color="gray.600">
            Il link e il Qr code qui riportati, permettono all'info point
            interessato di vedere in sola lettura la tabella dei punti
            d'interesse configurabile per data.
          </Text>

          <Flex
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Input
              id={"op_link"}
              disabled
              opacity={"1 !important"}
              value={url}
            />
            <Button
              m={2}
              colorScheme={"blackAlpha"}
              size={"md"}
              variant="outline"
              onClick={() => {
                const copyText = document.getElementById(
                  "op_link"
                ) as HTMLInputElement;
                copyText.select();
                copyText.setSelectionRange(0, 99999);

                try {
                  navigator.clipboard.writeText(copyText.value);
                  toast({
                    title: `Link info point copiato!`,
                    //description: err.desc,
                    status: "info",
                    isClosable: true,
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Copia Link
            </Button>
          </Flex>

          <Divider style={{ marginTop: 30, marginBottom: 10 }} />

          <VStack m={1} alignItems={"flex-start"}>
            <Button
              w={200}
              colorScheme={"brand"}
              size={"md"}
              onClick={() => {
                console.log(data?.adminArea?.shortName);
                generateCode({
                  variables: {
                    shortName: data?.adminArea?.shortName,
                  },
                });
                /* props
                  .secureManage({
                    variables: {
                      _ids: [props._id],
                      secure: {
                        reNewCode: true,
                        permissions: {
                          calendar: permissions.calendar,
                          manual: permissions.manual,
                        },
                      },
                    },
                  })
                  .then((res) => {
                    console.log(res);
                  }); */
              }}
            >
              Genera nuovo Link
            </Button>
          </VStack>
        </Flex>
        <Flex
          bgColor={"#fff"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection="column"
          p={2}
        >
          <Box
            borderRadius={20}
            borderColor={"brand.500"}
            borderWidth={10}
            p={5}
          >
            <QRCode size={180} width={"150px"} value={url} />
          </Box>

          <VStack hidden m={1} mt={2} alignItems={"flex-start"}>
            <Button
              w={200}
              variant="outline"
              colorScheme={"brand"}
              size={"md"}
              onClick={() => {
                /* props
                  .getQrPdf({ variables: { _id: props._id } })
                  .then((res) => {
                    if (res?.data?.adminPoiGetQrCodePdf) {
                      const name =
                        props.resource.name?.find((n) => n.langIso === "it")
                          ?.value || "";
                      downloadPDF(
                        res.data.adminPoiGetQrCodePdf,
                        name.replace(/ /g, "_").toLowerCase()
                      );
                    } else {
                      console.warn("No pdf found " + props._id);
                    }
                  }); */
              }}
            >
              Download PDF
            </Button>
          </VStack>
          <VStack m={1} hidden alignItems={"flex-start"}>
            <Button
              w={200}
              variant="outline"
              colorScheme={"brand"}
              size={"md"}
              onClick={() => {
                /* props
                    .getQrEmail({ variables: { _ids: [props._id] } })
                    .then((res) => {
                      onOpenSuccess();
                    }); */
              }}
            >
              Inoltra <EmailIcon ml={2} />
            </Button>
          </VStack>
        </Flex>
      </Flex>
      <Modal isOpen={isOpenSuccess} onClose={onCloseSuccess}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody w={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Flex
              mt={4}
              px={2}
              w={"100%"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                fontSize={"xl"}
                fontWeight={"bold"}
                mt={4}
                textAlign={"center"}
              >
                Email inoltrata correttamente all'operatore
              </Text>
              <CheckIcon ml={2} fontSize={"8xl"} color="green.500" />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand"
              onClick={() => {
                onCloseSuccess();
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {loading || rcLoading ? <Loading /> : null}
    </Card>
  );
};

export { InfoPointLink };
