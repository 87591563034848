import {
  Badge,
  Button,
  Card,
  Checkbox,
  Circle,
  Divider,
  Flex,
  Hide,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Switch,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import {
  CalendarIcon,
  CheckIcon,
  EmailIcon,
  ExternalLinkIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import styled from "@emotion/styled";

import { AddRangeDrawer } from "components/Calendar/AddRangeDrawer";
import Select from "react-select";
import { Resource } from "stores/queries/area";
import theme from "theme/theme";
import { MowiIcon, MowiIcons } from "ui/MowiIcon/Icon";
import { AddRangeMutation } from "components/Poi/types";
import { SearchBar } from "./SearchBar";
import { STORED_POI_FILTERS } from "stores/db/filtersStore";
import { secureInfos } from "stores/db/appstate";
import { Loading } from "ui/Loading/Loading";

function isToday(dateString: string) {
  // Convert the input date string to a Date object
  const inputDate = new Date(dateString);

  // Get the current date
  const today = new Date();

  // Compare the year, month, and day of the input date with today's date
  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  );
}

const filtersData = [
  "ristoranti-e-pizzerie",
  "borghi-e-localita-turistiche",
  "cultura",
  "attivita-outdoor",
  "centri-fondo",
  "sulla-neve",
  "farmacie-e-numeri-utili",
  "servizi-utili",
  "natura",
  "punti-panoramici",
  "scuole-di-sci",
  "centri-benessere",
  "wellness",
  "grande-guerra",
  "botteghe-e-negozi",
  "shopping",
  "produzioni-agricole",
  "guide-mtb",
  "in-bici",
  "chiese-e-santuari",
  "a-piedi",
  "falesie",
  "caseifici",
  "parchi-naturali-e-aree-protette",
  "bar",
  "ski-area",
  "rifugi-e-ristoranti-in-quota",
  "altre-attivita",
  "parchi-gioco",
  "e-bike-e-bici",
  "noleggi",
  "piste-da-slittino",
  "laghi-balneabili-e-spiagge",
  "luoghi-incantevoli",
  "musei-ed-ecomusei",
  "campi-da-calcio-e-palazzetti",
  "enoteche-e-birrifici",
  "laghetti-alpini-e-cascate",
  "pasticcerie",
  "malghe",
  "agriturismi",
  "piscine",
  "fattorie-didattiche",
  "agritur-e-malghe",
  "cammino",
  "giardini-e-orti-botanici",
  "golf",
  "sci-e-ciaspole",
  "impianti-di-risalita",
  "cantine-e-distillerie",
  "parco-giochi-sulla-neve",
  "salumifici-e-macellerie-e-troticolture",
  "downhill-bike-park",
  "panifici-e-pasticcerie",
  "maneggi",
  "piste-per-pattinaggio",
  "snowpark",
  "castelli-e-palazzi",
  "percorsi-kneipp-e-sensoriali",
  "parapendio",
  "altre-attrezzature-sportive",
  "taxi-e-servizi-con-conducente",
  "trasporti",
  "guide-alpine-e-accompagnatori",
  "terme",
  "rafting-e-hydrospeed",
  "sull-acqua",
  "arrampicata-indoor",
  "skibus-e-navette",
  "parchi-avventura",
  "trekking-ed-escursioni",
  "kayak-canoa-e-sup",
  "ispirazione",
  "bicigrill",
  "mountain-bike",
  "enoteche-e-birrerie",
  "autofficine",
  "mobilita",
  "dolomiti-unesco",
  "artigianato",
  "canyoning",
  "itinerari",
  "navigazione",
  "canyon-e-grotte",
];

type ReactSelectOption = {
  value: string;
  label: string;
};
const options: ReactSelectOption[] = [];

type TableRow = {
  id: string;
  name: string;
  status?: boolean;
  lastUpdate?: string;
  updatedBy?: string;
  warnings: number;
  filters: string[];
  hours: string[];
  search: string;
  email: string;
  category?: string;
  week: boolean[];
};

// for (let index = 0; index < filtersData.length; index++) {
//   options.push({ value: filtersData[index], label: filtersData[index] });
// }
filtersData.sort().forEach((filter) => {
  options.push({ value: filter, label: filter });
});

const tableColumn = [
  {
    Header: "ID",
    accessor: "id",
    disableSortBy: true,
  },
  /*   {
    Header: "CHECK",
    disableSortBy: true,
  }, */
  {
    Header: "NOME",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "CATEGORIA",
    accessor: "filters",
  },
  {
    Header: "CATEGORIA",
    accessor: "category",
  },
  {
    Header: "STATO",
    accessor: "status",
    disableSortBy: true,
  },
  {
    Header: "ORARIO",
    accessor: "hours",
    disableSortBy: true,
  },
  {
    Header: "SETTIMANA",
    accessor: "week",
    disableSortBy: true,
  },
  /*   {
    Header: "AVVISI",
    accessor: "warnings",
  }, */
  /*   {
    Header: "CALENDARIO MODIFICATO DA",
    accessor: "updatedBy",
  },
  {
    Header: "OPERATORE",
    accessor: "operator",
    disableSortBy: true,
  }, */
  /*   {
    Header: "CMS",
    accessor: "cms",
    disableSortBy: true,
  }, */
] as const satisfies readonly Column<TableRow>[];

type Header = (typeof tableColumn)[number]["Header"];

export const StyleWrapper = styled.div`
  .basic-multi-select {
    display: flex;
    flex: 1;
    margin-right:10,
    margin-left:10,
    font-family: Roboto
    overflow: hidden;
  }
  .select__control--is-focused {
    background-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px !important;
    border-radius: 20px;
    box-shadow: 0 0 0 0px rgb(244, 247, 254);
  }

  .select__control--menu-is-open {
    background-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px !important;
    border-radius: 20px;
  }

  .select__control {
    width:100%;
    background-color: rgb(244, 247, 254);
    border-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px;
    border-radius: 20px;
  }
  .select__menu {
    z-index:99999999
  }

  .select__multi-value {
    background-color: ${theme.colors.gray[400]};
    border-radius:10px;
    overflow: hidden;
  }
  .select__multi-value__label, .select__multi-value__remove {
    color: #fff
  }

  .select__option {
    font-size: 14px;
  }

  .select__placeholder {
    color: ${theme.colors.gray[400]} !important;
  } 
`;

type GetQrMutation = ({
  variables,
}: {
  variables: {
    _ids: string | string[];
  };
}) => Promise<any>;

interface ResourceTableInfosProps {
  data: {
    poi: Resource;
    dailyTimehours: string[];
    weekOpeningDays: boolean[];
  }[];
  date: string;
  getQrEmail: GetQrMutation;
  title: string;
  loading: any;
  itemPath: string;
  icon: MowiIcons;
  type: "track" | "transport" | "poi";
  area: string;
}

const HIDDEN_COLS: {
  [key in ResourceTableInfosProps["type"]]: Header[];
} = {
  track: ["EMAIL"],
  transport: ["EMAIL", "CATEGORIA"],
  poi: ["CATEGORIA", "STATO"],
};

const ResourceTableInfos = (props: ResourceTableInfosProps) => {
  const toast = useToast();

  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [tableFilteredData, setTableFilteredData] = useState<TableRow[]>([]);
  const [storeInitialized, setStoreInitialized] = useState(false);

  const [strSearch, setStrSearch] = useState("");
  const [filters, setActualFilters] = useState<readonly ReactSelectOption[]>(
    []
  );
  const storeFilters = (filters: readonly ReactSelectOption[]) => {
    if (props.type == "poi") {
      STORED_POI_FILTERS.category(filters);
    }
  };
  const storeSearch = (search: string) => {
    if (props.type == "poi") {
      STORED_POI_FILTERS.search(search);
    }
  };

  const searchTimeout = useRef(null);
  const onSearchChange = (search: string) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(() => {
      setStrSearch(search);
      storeSearch(search);
    }, 300);
  };

  const setFilters = (newFilters: readonly ReactSelectOption[]) => {
    setActualFilters(newFilters);
    storeFilters(newFilters);
  };

  React.useEffect(() => {
    if (props.type === "poi") {
      setStrSearch(STORED_POI_FILTERS.search());
      setActualFilters(STORED_POI_FILTERS.category());
      setStoreInitialized(true);
    }
  }, []);
  React.useEffect(() => {
    if (!storeInitialized) return;
    calcFilteredData(filters, strSearch);
  }, [filters, strSearch, tableData, storeInitialized]);

  const calcFilteredData = React.useCallback(
    (newFilters: readonly ReactSelectOption[], newSearch?: string) => {
      if (newSearch && newSearch.length > 2) {
        let filteredItems = tableData.filter((row) => {
          let inFilters = true;
          if (newFilters?.length > 0)
            inFilters = newFilters.some((filter) =>
              row.filters?.includes(filter.value)
            );
          if (inFilters) {
            return row.search.indexOf(newSearch) > -1;
          } else {
            return null;
          }
        });
        setTableFilteredData(filteredItems);
      } else if (newFilters?.length > 0) {
        const filteredItems = tableData.filter((obj) =>
          newFilters.some((filter) => obj.filters?.includes(filter.value))
        );
        setTableFilteredData(filteredItems);
      } else if (tableData.length > 0) {
        if (searchTimeout.current) {
          clearTimeout(searchTimeout.current);
        }
        setTableFilteredData(tableData);
      }
    },
    [tableData]
  );

  useEffect(() => {
    if (!props.data) return;
    let resourcesArr: TableRow[] = [];
    for (let i = 0; i < props.data.length; i++) {
      const resource = props.data[i]?.poi;
      if (!resource) continue;
      const hours = props.data[i]?.dailyTimehours;
      const week = props.data[i]?.weekOpeningDays;

      const name = resource.name?.find((n) => n.langIso === "it")?.value || "";

      if (i === 0) console.log(resource);

      resourcesArr.push({
        id: resource._id,
        name: name,
        status: resource.statuses?.manual?.status,
        warnings: resource?.eventsCount || 0,
        hours,
        filters: resource.filters,
        search: name.toLowerCase(),
        email: resource.email,
        week,
      });
    }
    setTableData(resourcesArr);
    setTableFilteredData(resourcesArr);
  }, [props.data]);

  const tableInstance = useTable<TableRow>(
    {
      columns: tableColumn,
      data: tableFilteredData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
  } = tableInstance;
  initialState.pageSize = 10;

  const hiddenColSM = [
    "ID",
    "EMAIL",
    "CHECK",
    "ULTIMA DATA A CALENDARIO",
    "CALENDARIO MODIFICATO DA",
  ];

  const { pageIndex, pageSize } = state;

  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        overflowY={"hidden"}
        paddingBottom={"0px !important"}
        bg={"white"}
        borderRadius={30}
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      >
        <Flex px="25px" justify="space-between" mt={5} mb={5} align="center">
          <Flex align="center" color={"brand.500"}>
            <MowiIcon name={props.icon} size={30} />
            <Text
              color={"gray.700"}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              {props.title}
            </Text>
          </Flex>

          {props.type === "poi" && (
            <Hide below="md">
              <Flex style={{ flex: 1 }} mx={5}>
                <StyleWrapper style={{ flex: 1 }}>
                  <Select
                    placeholder={"Filtri categoria..."}
                    isMulti
                    name="categories"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={setFilters}
                    value={filters}
                  />
                </StyleWrapper>
              </Flex>
            </Hide>
          )}

          <SearchBar
            value={strSearch}
            setSearch={onSearchChange}
            transform={(str) => str.toLowerCase()}
          />
          {/*  <Menu /> */}
        </Flex>
        <Flex maxHeight={"calc(100vh - 320px)"} overflowY={"auto"}>
          <Table {...getTableProps()} variant="striped">
            <Thead bgColor={"white"} position="sticky" top={0} zIndex="docked">
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) =>
                    HIDDEN_COLS[props.type].includes(
                      column.Header as Header
                    ) ? undefined : (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        pe="10px"
                        key={index}
                        borderColor={"gray.200"}
                        className={
                          hiddenColSM.includes(String(column.Header))
                            ? "hidden-sm"
                            : undefined
                        }
                        style={{
                          display: index === 0 ? "none" : undefined,
                        }}
                      >
                        <Flex
                          justify="flex-start"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {column.render("Header")}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " ↓"
                              : " ↑"
                            : ""}
                        </Flex>
                      </Th>
                    )
                  )}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data;
                      if (
                        HIDDEN_COLS[props.type].includes(
                          cell.column.Header as Header
                        )
                      )
                        return null;
                      if (cell.column.Header === "NOME") {
                        data = (
                          <Link
                            href={`?si=${secureInfos()}&a=${props.area}&_id=${
                              row.cells[0].value
                            }`}
                          >
                            <Flex direction={"row"} align="center">
                              {cell.value}
                            </Flex>
                          </Link>
                        );
                        /*}  else if (cell.column.Header === "CATEGORIA") {
                        data = (
                          <>
                            {cell.value &&
                              cell.value.map((res: string, index: number) => {
                                return (
                                  <Button
                                    key={
                                      "filter" +
                                      row.cells[0].value +
                                      "-" +
                                      index
                                    }
                                    onClick={() => {
                                      //setStrSeach(res.toLowerCase());
                                    }}
                                    variant={"link"}
                                  >
                                    {res}
                                  </Button>
                                );
                              })}
                          </>
                        ); */
                      } else if (cell.column.Header === "ORARIO") {
                        data = (
                          <Flex direction={"row"} align="center">
                            {isToday(props.date) &&
                            typeof row?.values["status"] === "boolean" &&
                            !row?.values["status"] ? (
                              <Tag
                                key={"hours_" + row.cells[0].value + "-00"}
                                colorScheme={"red"}
                                size={"sm"}
                                variant="solid"
                                m={1}
                              >
                                CHIUSO MANUALMENTE
                              </Tag>
                            ) : (
                              <>
                                {!cell.value && (
                                  <Tag
                                    key={"hours_" + row.cells[0].value + "-01"}
                                    colorScheme={"gray"}
                                    size={"sm"}
                                    variant="solid"
                                    m={1}
                                  >
                                    N.D.
                                  </Tag>
                                )}
                                {cell.value?.length === 0 && (
                                  <Tag
                                    key={"hours_" + row.cells[0].value + "-01"}
                                    colorScheme={"red"}
                                    size={"sm"}
                                    variant="solid"
                                    m={1}
                                  >
                                    CHIUSO
                                  </Tag>
                                )}
                              </>
                            )}

                            {cell.value?.map((res: string, i: number) => {
                              return (
                                <Tag
                                  key={"hours_" + row.cells[0].value + "-" + i}
                                  colorScheme={"green"}
                                  size={"sm"}
                                  variant="solid"
                                  m={1}
                                >
                                  {res}
                                </Tag>
                              );
                            })}
                          </Flex>
                        );
                      } else if (cell.column.Header === "AVVISI") {
                        data = (
                          <Tag
                            width={"100%"}
                            textAlign={"center"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            size={"sm"}
                            variant="solid"
                            bgColor={cell.value ? "yellow.500" : "gray.200"}
                            color={cell.value ? "white" : "gray.600"}
                          >
                            {cell.value ? cell.value : "/"}
                          </Tag>
                        );
                      } else if (cell.column.Header === "ID") {
                        data = null;
                      } else if (cell.column.Header === "SETTIMANA") {
                        data = (
                          <Flex direction={"row"} align="center">
                            {cell.value?.map((res: string, i: number) => {
                              let color = "gray";
                              if (typeof cell.value[i] === "boolean") {
                                if (cell.value[i]) {
                                  color = "green";
                                } else {
                                  color = "red";
                                }
                              }
                              let today = new Date(props.date);
                              let day = new Date();
                              day.setDate(today.getDate() + i + 1);
                              let dayOfMonth = day.getDate();
                              let options = { weekday: "short" };
                              let dayOfWeek = day.toLocaleDateString(
                                "it-IT",
                                options as any
                              );

                              return (
                                <Tag
                                  key={
                                    "week_" +
                                    row.cells[0].value +
                                    "_" +
                                    i +
                                    "-01"
                                  }
                                  colorScheme={color}
                                  size={"sm"}
                                  style={{
                                    width: 24,
                                    height: 24,
                                    fontSize: 9,
                                    fontWeight: 800,
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textTransform: "uppercase",
                                  }}
                                  variant="solid"
                                  m={1}
                                >
                                  {dayOfMonth}
                                  <br />
                                  {dayOfWeek}
                                </Tag>
                              );
                            })}
                          </Flex>
                        );
                      } else {
                        data = (
                          <Text
                            fontSize="sm"
                            fontWeight="700"
                            whiteSpace="nowrap"
                          >
                            {cell.value}
                          </Text>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          className={
                            hiddenColSM.includes(String(cell.column.Header))
                              ? "hidden-sm"
                              : null
                          }
                          key={index}
                          fontSize={{ sm: "14px" }}
                          //minW={{ sm: "10px",  md: "200px", lg: "auto" }}
                          borderColor="transparent"
                          style={{ display: index === 0 ? "none" : undefined }}
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          paddingX={5}
          style={{ borderTopWidth: 1 }}
          paddingY={1}
        >
          <Flex flexDirection={"row"}>
            <Button
              mx={2}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>{" "}
            <Button
              mx={2}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Precedente
            </Button>{" "}
            <Button mx={2} onClick={() => nextPage()} disabled={!canNextPage}>
              Prossima
            </Button>{" "}
            <Button
              mx={2}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Flex>{" "}
          <Badge width={120} mx={2}>
            Pagina{" "}
            <strong>
              {pageIndex + 1} di {pageOptions.length}
            </strong>{" "}
          </Badge>
        </Flex>
        {!props.loading && page.length < 1 && (
          <Flex
            w="100%"
            p={6}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Text>Nessun risultato soddisfa la tua richiesta</Text>
            <Button
              mt={6}
              onClick={() => {
                onSearchChange("");
                setFilters([]);
              }}
            >
              Cancella filtro
            </Button>
          </Flex>
        )}
        {!tableData.length && props.loading ? (
          <>
            <Skeleton opacity={0.7} height={"60px"} mb={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} mt={1} mb={4} />
          </>
        ) : null}
        {props.loading && <Loading />}
      </Card>
      <HStack>
        <Text>
          Mio Trentino Business - © 2024 Trentino Marketing All rights reserved
        </Text>
      </HStack>
    </>
  );
};

export { ResourceTableInfos };
