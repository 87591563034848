import * as React from "react";

import { createApolloClient } from "../libs/apollo/createClient";
import {
  scopes,
  user,
  area,
  skiarea,
  secure,
  secureInfos,
} from "../stores/db/appstate";
import { Auth, DB_AUTH } from "../stores/db/auth";
import { LOG } from "../config";

const log = LOG.extend("INITHOOK");

// Hooks per tutte le risorse iniziali
const useInitResource = () => {
  const [initResource, setInitResource] = React.useState<{
    apolloClient: any;
    auth: Auth | null;
  }>();

  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      let apolloClient: any = null;
      let auth: Auth = null;
      try {
        // Inizializzo client apollo
        apolloClient = await createApolloClient();

        log.debug("init apollo");

        let urlParams = new URLSearchParams(window.location.search);
        let secureCode = urlParams.get("s");
        let secureCodeInfos = urlParams.get("si");

        if (secureCodeInfos) {
          secureInfos(secureCodeInfos);
        } else if (secureCode) {
          secure(secureCode);
        } else {
          // Inizializzo authenticazione
          auth = await DB_AUTH.get();

          log.debug("init auth:", auth);

          if (auth && auth.user) {
            area(auth.area);
            skiarea(auth.skiarea);
            scopes(auth.scopes);
            user(auth.user);
          }
        }
      } catch (e) {
        console.warn(e);
      } finally {
        setInitResource({
          apolloClient,
          auth,
        });
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return initResource;
};

export { useInitResource };
