import { CachePersistor } from "apollo3-cache-persist";
import {
  ApolloClient,
  from,
  gql,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { createNetworkStatusNotifier } from "react-apollo-network-status";

import { ENV, LOG, __DEV__ } from "../../config";
import { cacheStorage } from "../../libs/storages";
import { authLink } from "./authLink";
import { onErrorRefreshTokenLink } from "./onErrorRefreshTokenLink";
import {
  scopes,
  user,
  area,
  skiarea,
  secure,
  secureInfos,
} from "../../stores/db/appstate";

const log = LOG.extend("APOLLOCLIENT");

let oertl: any = onErrorRefreshTokenLink;
let client: any = null;

const { link: NetworkStatusLink, useApolloNetworkStatus } =
  createNetworkStatusNotifier();

// Funzione che recupera la cache e crea il client apollo
const createApolloClient = async () => {
  log.debug("CREATE CLIENT ON URI: " + ENV.backend.uri);

  try {
    const httpLink = new HttpLink({ uri: ENV.backend.uri });

    const Http = NetworkStatusLink.concat(httpLink);

    const typeDefs = gql`
      extend type Query {
        user: Boolean!
        scopes: Array
      }
    `;

    // Configuro cache poer rendere reattivo il parametro locale "user"
    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            user: {
              read() {
                return user();
              },
            },
            scopes: {
              read() {
                return scopes();
              },
            },
            area: {
              read() {
                return area();
              },
            },
            skiarea: {
              read() {
                return skiarea();
              },
            },
            secure: {
              read() {
                return secure();
              },
            },
            secureInfos: {
              read() {
                return secureInfos();
              },
            },
          },
        },
      },
    });

    // Configurazione persistenza cache in local storage / Async Storage
    const persistor = new CachePersistor({
      //trigger: "background",
      storage: cacheStorage,
      cache,
      key: ENV.db.cacheKey,
      //maxSize: 2000000,
      debug: __DEV__,
    });

    try {
      await persistor.restore();
      __DEV__ &&
        persistor.getSize().then((cacheSize) => {
          log.debug("CACHE RESTORED, SIZE: " + cacheSize + " bytes");
        });
    } catch ({ message }: any) {
      log.error(`Unable to Restore Cache: ${message}`);
    }

    client = new ApolloClient({
      cache,
      link: from([authLink, oertl, Http]),
      name: "tgp-web-dashboard",
      version: ENV.deviceInfo.app.version,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "network-only",
          errorPolicy: "all",
        },
        mutate: {
          errorPolicy: "all",
        },
      },
      typeDefs,
    });

    client.onClearStore(async () => {
      await persistor.purge();
      log.warn("Offline Cache Purged");
    });
  } catch ({ message }: any) {
    log.error(`Create client ERROR: ${message}`);
  }
  return client;
};

// Funzione che restituisce il client all'esterno
const getApolloClient = () => {
  return client;
};

export { createApolloClient, getApolloClient, useApolloNetworkStatus };
