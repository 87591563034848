import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { Flex, HStack, Text, useToken } from "@chakra-ui/react";

import { NavRoute } from "routes";
import { MowiIcon } from "ui/MowiIcon/Icon";

interface SideBarLinkProps {
  route: NavRoute;
}

const SideBarLink = (props: SideBarLinkProps) => {
  const location = useLocation();
  const [brand, gray] = useToken(
    // the key within the theme, in this case `theme.colors`
    "colors",
    // the subkey(s), resolving to `theme.colors.red.100`
    ["tgpBlue", "secondaryGray.600"]
    // a single fallback or fallback array matching the length of the previous arg
  );

  const id = location.pathname.split("/")[2];

  const activeRoute = (routeName: string) => {
    return location?.pathname.includes(routeName) ? brand : gray;
  };

  return (
    <NavLink to={props.route.path.replace(":id", id)}>
      <Flex
        align="center"
        justifyContent="space-between"
        w="100%"
        ps="17px"
        mb="0px"
        //ml={!route.submenu ? "0px" : "24px"}
      >
        <HStack mb="6px" spacing={"26px"}>
          <Flex w="100%" alignItems="center" justifyContent="center">
            <MowiIcon
              style={{ marginRight: 10 }}
              size={22}
              name={props.route.icon}
              color={activeRoute(props.route.path.toLowerCase())}
            />
            {/* <Icon
                  //as={route.icon}
                  width="20px"
                  height="20px"
                  me="12px"
                  color={activeRoute(route.path.toLowerCase())}
                /> */}
            <Text
              me="auto"
              color={activeRoute(props.route.path.toLowerCase())}
              fontWeight="500"
            >
              {props.route.name}
            </Text>
          </Flex>
        </HStack>
      </Flex>
    </NavLink>
  );
};

export { SideBarLink };
