import { ENV, LOG } from "../../config";
import { DB } from "../../libs/storages";

const log = LOG.extend("DB");

interface Auth {
  user?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  scopes?: string[];
  skiarea?: string;
  area?: string;
}

let ACTUAL_AUTH_INIT: Auth = {
  user: null,
  refreshToken: null,
  accessToken: null,
  scopes: [],
  skiarea: null,
  area: null,
};

let ACTUAL_AUTH: Auth = ACTUAL_AUTH_INIT;

const DB_AUTH = {
  get: async () => {
    let authSaved = null;
    try {
      authSaved = await DB.getObject(ENV.db.authKey);
    } catch ({ message }: any) {
      log.error(`Get DB_AUTH ERROR: ${message}`);
    }
    if (authSaved) {
      ACTUAL_AUTH = authSaved;
    } else {
      log.warn("No DB_AUTH found");
      ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT };
    }
    return ACTUAL_AUTH;
  },
  set: async (params: Auth) => {
    ACTUAL_AUTH = {
      ...ACTUAL_AUTH,
      ...params,
    };
    try {
      await DB.setObject(ENV.db.authKey, ACTUAL_AUTH);
    } catch ({ message }: any) {
      log.error(`Set DB_AUTH ERROR: ${message}`);
    }
    return ACTUAL_AUTH;
  },
  clearUser: async () => {
    log.debug("CLEAR USER AUTHPARAM");
    ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT };
    try {
      await DB.setObject(ENV.db.authKey, ACTUAL_AUTH);
    } catch ({ message }: any) {
      log.error(`ClearUser DB_AUTH ERROR: ${message}`);
    }
    return ACTUAL_AUTH;
  },
  clear: async () => {
    log.debug("CLEAR AUTHPARAM");
    ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT };
    try {
      await DB.remove(ENV.db.authKey);
    } catch ({ message }: any) {
      log.error(`Remove DB_AUTH ERROR: ${message}`);
    }
    return ACTUAL_AUTH;
  },
};

export { DB_AUTH, ACTUAL_AUTH };
export type { Auth };
