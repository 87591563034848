import React from "react";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  POI_CALENDAR,
  POIS_CALENDAR_ADDRANGE,
  POI_CALENDAR_RESET,
  POI,
  POI_FRAGMENT,
} from "stores/queries/pois";
import { CalendarDay, Resource } from "stores/queries/area";
import { OpeningCalendar } from "components/Calendar/OpeningCalendar";

interface PoiCalendarProps {
  _id: string;
}

const PoiCalendar = (props: PoiCalendarProps) => {
  const [getCalendar, { loading }] = useLazyQuery<{
    adminPoiGetCalendarDays: CalendarDay[];
  }>(POI_CALENDAR, {
    fetchPolicy: "network-only",
  });

  const { data: PoiData, loading: poiLoading } = useQuery(POI, {
    fetchPolicy: "network-only",
    variables: {
      _id: props._id,
    },
  });

  const [addRange, { loading: arLoading }] = useMutation(
    POIS_CALENDAR_ADDRANGE
  );

  const [resetCalendar, { loading: rcLoading }] =
    useMutation(POI_CALENDAR_RESET);

  const getDays = async (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => {
    let days = null;

    try {
      const res = await getCalendar({
        variables: {
          from: data.variables.from,
          to: data.variables.to,
          _id: data.variables._id,
        },
      });
      days = res?.data?.adminPoiGetCalendarDays;
    } catch (error) {
      console.log(error);
    }

    return days;
  };
  return (
    <OpeningCalendar
      _id={props._id}
      getDays={getDays}
      addRange={addRange}
      resetCalendar={resetCalendar}
      loading={loading || arLoading || poiLoading}
      manualStatus={PoiData?.adminPoi?.statuses.manual}
    />
  );
};

export { PoiCalendar };
