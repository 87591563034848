import { gql } from "@apollo/client";

import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
} from "@apollo/client";
import { StatusUpdateOrigin } from "libs/apollo/__generated__/graphql";

enum ETgpArea {
  "Val di Fassa" = "apt-val-di-fassa",
  "Trento" = "apt-trento",
  "San Martino" = "apt-san-martino",
  "Paganella" = "apt-paganella",
  "Garda Trentino" = "apt-garda-trentino",
  "Madonna di Campiglio" = "apt-madonna-di-campiglio",
  "Fiemme" = "apt-fiemme",
  "Val di Sole" = "apt-sole",
  "Alpe Cimbra" = "apt-alpe-cimbra",
  "Valsugana" = "apt-valsugana",
  "Rovereto" = "apt-rovereto",
  "Val di Non" = "apt-non",
  "Pine Cembra" = "apt-pine-cembra",
}

export const ETgpAreaToName: {
  [key in ETgpArea]: string;
} = {
  "apt-val-di-fassa": "Val di Fassa",
  "apt-trento": "Trento",
  "apt-san-martino": "San Martino",
  "apt-paganella": "Paganella",
  "apt-garda-trentino": "Garda Trentino",
  "apt-madonna-di-campiglio": "Madonna di Campiglio",
  "apt-fiemme": "Fiemme",
  "apt-sole": "Val di Sole",
  "apt-alpe-cimbra": "Alpe Cimbra",
  "apt-valsugana": "Valsugana",
  "apt-rovereto": "Rovereto",
  "apt-non": "Val di Non",
  "apt-pine-cembra": "Pine Cembra",
};

type ApolloMutation = (
  options?: MutationFunctionOptions<
    any,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >
) => Promise<any>;

type ApolloMutationOperator = (
  options?: MutationFunctionOptions<
    any,
    OperationVariables,
    {
      headers: {
        secureCode: string;
      };
    },
    ApolloCache<any>
  >
) => Promise<any>;

type DateRangeType = {
  start: Date;
  end: Date;
};

type CalendarDay = {
  day: string;
  schedules: string[];
};

type Resource = {
  _id: string;
  name?: {
    langIso: string;
    value: string;
  }[];
  email?: string;
  statuses: {
    manual?: {
      status?: boolean;
      lastUpdate?: string;
      expiration?: string;
      motivation?: {
        langIso: string;
        value: string;
      }[];
    };
    live?: {
      status?: boolean;
      updatedOrigin?: StatusUpdateOrigin;
      lastUpdate?: string;
    };
    daily?: {
      status: boolean;
      updatedOrigin: "MANUAL" | "CALENDAR" | "EXTERNAL";
      lastUpdate: string;
    };
    calendar?: {
      status?: boolean;
      lastUpdate?: string;
    };
    external?: {
      status: boolean;
      lastUpdate: string;
    };
  };
  externalInfo?: {
    tgpId?: string;
    externalId?: string;
    externalSource?: string;
    updateLink?: string;
  };
  calendar?: {
    timeEncoding: {
      times: string;
    };
  };
  calendarMetadata?: {
    lastOperatorCheck?: Date;
    isCalendarUpdated?: boolean;
    lastDay?: string;
    lastUpdate?: Date;
    updatedBy?: string;
    threeMonthAlertSent?: boolean;
    threeMonthAlertDate?: string;
    emptyCalendarAlertSent?: boolean;
    emptyCalendarAlertDate?: string;
  };
  calendarSync?: {
    googleRefreshToken?: string;
    googleAccountId?: string;
    googleLocationId?: string;
    googleFirstSync?: string;
    googleLastSync?: string;
    googleLastSyncResult?: boolean;
    googleLastSyncError?: string;
    googleLastSyncErrorDate?: string;
    googleSyncInError?: boolean;
  };
  geoLocation?: {
    coordinatesMultiLineString: string;
    coordinatesLineString: string;
    coordinatesPoint: string;
  };
  owner: string;
  skiarea?: string;
  area: string;
  secure: {
    code?: string;
    lastRefresh?: string;
    permissions?: {
      manual?: boolean;
      calendar?: boolean;
    };
  };
  eventsCount: number;
  filters?: string[];
  termsAndConditions?: {
    accepted?: string;
    version?: string;
    newVersion?: string;
  };
  category?: string;
  emailSent?: string;
};

type Warning = {
  _id: string;
  createdAt: string;
  updatedAt: string;
  title: {
    langIso: string;
    value: string;
  }[];
  description: {
    langIso: string;
    value: string;
  }[];
  schedule: {
    start: string;
    end: string;
  };
  objectSource: {
    sourceId: string;
    objectType: string;
  };
};

const WARNINGS = gql`
  query adminEvents($objectId: ID!, $objectType: EventObjectType!) {
    adminEvents(objectId: $objectId, objectType: $objectType) {
      _id
      createdAt
      updatedAt
      title {
        langIso
        value
      }
      description {
        langIso
        value
      }
      schedule {
        start
        end
      }
      objectSource {
        sourceId
        objectType
      }
    }
  }
`;

type WarningAddInput = {
  title: {
    langIso: string;
    value: string;
  }[];
  description: {
    langIso: string;
    value: string;
  }[];
  schedule: {
    start: Date;
    end: Date;
  };
};

const WARNING_ADD = gql`
  mutation adminEventCreate(
    $event: EventInput!
    $objectId: ID!
    $objectType: EventObjectType
  ) {
    adminEventCreate(
      event: $event
      objectId: $objectId
      objectType: $objectType
    ) {
      _id
    }
  }
`;

const WARNING_REMOVE = gql`
  mutation adminEventDelete($eventId: ID!) {
    adminEventDelete(eventId: $eventId)
  }
`;

const AREA = gql`
  query AdminArea($shortName: String!) {
    adminArea(shortName: $shortName) {
      _id
      name
      shortName
      infoPointCode
    }
  }
`;

const AREA_GENERATE_INFOPOINTCODE = gql(`
  mutation AdminRegenerateAreaInfoPointCode($shortName: String!) {
    adminRegenerateAreaInfoPointCode(shortName: $shortName) {
      _id
      name
      shortName
      infoPointCode
    }
  }  
`);

export {
  AREA,
  WARNINGS,
  WARNING_ADD,
  WARNING_REMOVE,
  AREA_GENERATE_INFOPOINTCODE,
  ETgpArea,
};
export type {
  ApolloMutation,
  ApolloMutationOperator,
  Resource,
  DateRangeType,
  CalendarDay,
  Warning,
  WarningAddInput,
};
