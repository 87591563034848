import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import React from "react";

type Props = {
  value: string;
  setSearch: (str: string) => void;
  transform?: (str: string) => string;
};

const SearchBar = ({ transform, setSearch, value }: Props) => {
  const [_query, _setQuery] = React.useState<string>("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    _setQuery(value);
    setSearch(transform ? transform(value) : value);
  };

  React.useEffect(() => {
    _setQuery(value);
  }, [value]);

  return (
    <InputGroup
      pl={2}
      w={{ base: "100%", md: "250px" }}
      position="relative"
      overflow="visible"
    >
      <Input
        variant="search"
        fontSize="sm"
        bg={"secondaryGray.300"}
        color={"gray.500"}
        onChange={onChange}
        value={_query}
        fontWeight="500"
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius="30px"
        placeholder="Cerca..."
      />
      {_query.length > 2 && (
        <InputRightElement /* width="4.5rem" */>
          <Button
            h="1.75rem"
            size="sm"
            onClick={() => {
              _setQuery("");
              setSearch("");
            }}
          >
            {"X"}
          </Button>
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export { SearchBar };
