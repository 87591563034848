import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { offset } from "@floating-ui/dom";
import { ProviderProps, StepType } from "@reactour/tour";
import React from "react";
import { ShepherdTour, Step, Tour } from "react-shepherd";
import { tourCopy } from "./texts";

type Steps = React.ComponentProps<typeof ShepherdTour>["steps"];

const click = (selector: string) => {
  try {
    const element = document.querySelector(selector) as HTMLElement | null;
    element.click();
  } catch (e) {
    console.log(selector, " not found");
  }
};
const clickElement = (selector: string[] | string, timeout = 300) => {
  return async () => {
    const selectors = Array.isArray(selector) ? selector : [selector];
    for (const selector of selectors) {
      await new Promise((resolve) => setTimeout(resolve, timeout));
      click(selector);
    }
  };
};

const clickIfUnchecked = async (selector: string) => {
  const checked = document.querySelectorAll<HTMLElement>(
    '[tour-id="weekday-checkbox"][data-checked]'
  );
  if (checked.length === 1) return;
  if (checked.length === 0) await clickElement(selector)();
  for (let i = 1; i < checked.length; i++) {
    checked[i].click();
  }
};
const sequentialAwait = async (...fns: (() => Promise<void>)[]) => {
  for (const fn of fns) {
    await fn();
  }
};

const steps = (t: Tour): Steps => {
  // t.on("complete", () => {
  //   clickElement(".manual-status-tab")();
  // });
  // const exitButton: Step.StepOptionsButton = {
  //   classes: "shepherd-button-secondary",
  //   text: "Esci",
  //   action: t.cancel,
  // };
  const completeButton: Step.StepOptionsButton = {
    classes: "shepherd-button-primary",
    text: "Fine",
    action: t.complete,
  };
  const previousButton: Step.StepOptionsButton = {
    classes: "shepherd-button-secondary",
    text: "Indietro",
    action: t.back,
  };
  const nextButton: Step.StepOptionsButton = {
    classes: "shepherd-button-primary",
    text: "Avanti",
    action: t.next,
  };
  const manualEndStep = {
    id: "end_tab1",
    buttons: [previousButton, completeButton],
    ...tourCopy.end,
    beforeShowPromise: clickElement([
      '[tour-id="copypage-cancel"]',
      '[tour-id="manual-status-tab"]',
    ]),
  };
  return [
    // {
    //   id: "welcome",
    //   buttons: [exitButton, nextButton],
    //   ...tourCopy.welcome,
    // },
    {
      id: "tab1",
      attachTo: { element: ".manual-status-tab", on: "top" },
      buttons: [previousButton, nextButton],
      ...tourCopy.manual_tab,
      beforeShowPromise: clickElement(".manual-status-tab"),
      modalOverlayOpeningRadius: {
        topRight: 20,
      },
    },
    {
      id: "current-status",
      attachTo: { element: '[tour-id="poi-status"]', on: "bottom" },
      buttons: [previousButton, nextButton],
      ...tourCopy.current_status,
      beforeShowPromise: clickElement('[tour-id="status-modal-close"]'),
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 5,
    },
    {
      id: "manual-status-modal",
      attachTo: { element: ".manual-status-modal", on: "bottom" },
      buttons: [previousButton, nextButton],
      ...tourCopy.closing_modal,
      beforeShowPromise: () => {
        const tab = document.querySelector(
          ".calendar-status-tab"
        ) as HTMLElement;
        if (!tab) t.addStep(manualEndStep, 3);
        return clickElement([".manual-status-btn"])();
      },
    },
    {
      id: "tab2",
      attachTo: { element: ".calendar-status-tab", on: "top" },
      buttons: [nextButton],
      ...tourCopy.calendar_tab,
      beforeShowPromise: clickElement(".calendar-status-tab"),
      modalOverlayOpeningRadius: {
        topLeft: 20,
      },
    },
    {
      id: "edit-weekday-btn",
      attachTo: { element: ".edit-weekday-btn", on: "bottom" },
      buttons: [previousButton, nextButton],
      ...tourCopy.weekday_edit_btn,
      modalOverlayOpeningRadius: 5,
      modalOverlayOpeningPadding: 5,
      beforeShowPromise: clickElement('[tour-id="daypage-cancel"]'),
    },
    {
      id: "daypage-hours",
      attachTo: { element: '[tour-id="daypage-hours"]', on: "bottom" },
      buttons: [previousButton, nextButton],
      ...tourCopy.daypage_hours,
      beforeShowPromise: clickElement([
        ".edit-weekday-btn",
        '[tour-id="daypage-open"]',
        '[tour-id="daypage-addtime"]',
      ]),
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 5,
    },
    {
      id: "daypage-close",
      attachTo: { element: '[tour-id="daypage-close"]', on: "bottom" },
      ...tourCopy.daypage_close,
      buttons: [previousButton, nextButton],
      beforeShowPromise: clickElement([
        ".edit-weekday-btn",
        '[tour-id="daypage-open"]',
      ]),
      modalOverlayOpeningRadius: 16,
    },
    {
      id: "weekday-checkbox",
      attachTo: { element: '[tour-id="weekday-checkbox"]', on: "bottom" },
      ...tourCopy.weekday_checkbox,
      buttons: [previousButton, nextButton],
      beforeShowPromise: () =>
        sequentialAwait(clickElement('[tour-id="daypage-cancel"]'), () =>
          clickIfUnchecked('[tour-id="weekday-checkbox"]')
        ),
      modalOverlayOpeningRadius: 5,
      modalOverlayOpeningPadding: 5,
    },
    {
      id: "copy-section",
      attachTo: { element: '[tour-id="copy-section"]', on: "top" },
      ...tourCopy.weekday_copy_buttons,
      buttons: [previousButton, nextButton],
      modalOverlayOpeningRadius: 5,
      beforeShowPromise: () =>
        sequentialAwait(clickElement('[tour-id="copypage-cancel"]'), () =>
          clickIfUnchecked('[tour-id="weekday-checkbox"]')
        ),
    },
    {
      id: "copy-period",
      attachTo: { element: '[tour-id="copypage-from-calendar"]', on: "bottom" },
      ...tourCopy.weekday_copy_period,
      buttons: [previousButton, nextButton],
      beforeShowPromise: () =>
        sequentialAwait(
          clickElement('[tour-id="copy-weekday-cancel"]'),
          () => clickIfUnchecked('[tour-id="weekday-checkbox"]'),
          clickElement(
            [
              '[tour-id="copy-period-btn"]',
              '[tour-id="copypage-from-trigger"]',
            ],
            500
          )
        ),
      when: {
        show: () =>
          setTimeout(clickElement('[tour-id="copypage-from-trigger"]'), 500),
      },
      floatingUIOptions: {
        middleware: [offset(20)],
      },
      modalOverlayOpeningRadius: 5,
    },
    {
      id: "copy-weekday",
      attachTo: { element: '[tour-id="copy-weekday"]', on: "bottom" },
      ...tourCopy.weekday_copy_weekday,
      buttons: [previousButton, nextButton],
      beforeShowPromise: () =>
        sequentialAwait(
          clickElement('[tour-id="copypage-cancel"]'),
          () => clickIfUnchecked('[tour-id="weekday-checkbox"]'),
          clickElement(['[tour-id="copy-day-btn"]', '[tour-id="weekday-btn"]'])
        ),
      modalOverlayOpeningRadius: 5,
      modalOverlayOpeningPadding: 10,
      floatingUIOptions: {
        middleware: [offset(20)],
      },
    },
    {
      id: "end",
      buttons: [previousButton, completeButton],
      ...tourCopy.end,
      beforeShowPromise: clickElement([
        '[tour-id="copy-weekday-cancel"]',
        '[tour-id="manual-status-tab"]',
      ]),
    },
  ];
};

const tourOptions: React.ComponentProps<typeof ShepherdTour>["tourOptions"] = {
  defaultStepOptions: {
    floatingUIOptions: {
      middleware: [offset(10)],
    },
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  useModalOverlay: true,
  keyboardNavigation: false,
};

export const reactSteps: StepType[] = [
  {
    selector: "[tour-id='center']",
    position: "center",
    content: (
      <VStack alignItems="flex-start">
        <Heading size="lg">Benvenuto</Heading>
        <Text>
          Questo tutorial ti guiderà nell'utilizzo della schermata di gestione
        </Text>
      </VStack>
    ),
  },
  {
    selector: ".manual-status-tab",
    content: (
      <Text>
        Utilizza questa schermata per modificare manualmente lo stato
        (apertura/chiusura) del POI
      </Text>
    ),
    action: clickElement(".manual-status-tab"),
    // actionAfter: clickElement(".calendar-status-tab"),
  },
  {
    selector: ".calendar-status-view",
    content: (
      <Text>
        Qui puoi controllare l'orario impostato correntemente a calendario
      </Text>
    ),
  },
  {
    selector: ".manual-status-btn",
    content: (
      <Text>
        Utilizza questo pulsante per modificare manualmente lo stato
        (apertura/chiusura) del POI
      </Text>
    ),
  },
  {
    selector: ".manual-status-modal",
    content: (
      <Text>
        Utilizza questa schermata per modificare manualmente lo stato del POI
      </Text>
    ),
    action: clickElement(".manual-status-btn"),
  },
  {
    selector: ".calendar-status-tab",
    content: (
      <Text>
        Utilizza questa schermata per modificare lo stato (apertura/chiusura)
        del POI in base al calendario
      </Text>
    ),
    action: clickElement(".calendar-status-tab"),
  },
  {
    selector: ".edit-weekday-btn",
    content: (
      <Text>
        Utilizza questa schermata per modificare lo stato del POI in un giorno
        della settimana
      </Text>
    ),
    actionAfter: clickElement(".edit-weekday-btn"),
    // action: clickElement(".edit-weekday-btn"),
  },
  {
    selector: '[tour-id="daypage-close"]',
    content: (
      <Text>Utilizza questo pulsante per impostare il giorno come chiuso</Text>
    ),
    mutationObservables: ['[tour-id="daypage-close"]'],
    resizeObservables: ['[tour-id="daypage-close"]'],
    action: clickElement(".edit-weekday-btn"),
  },
  {
    selector: "[tour-id='center']",
    position: "center",
    content: (
      <VStack alignItems="flex-start">
        <Heading size="lg">Fine</Heading>
        <Text>
          Questo è tutto, buon utilizzo! Per ulteriori informazioni contattataci
        </Text>
      </VStack>
    ),
  },
];

const ReactTourOptions: Omit<ProviderProps, "children"> = {
  steps: reactSteps,
  showBadge: false,
  disableInteraction: true,
  styles: {
    popover: (base) => ({
      ...base,
      borderRadius: 10,
      paddingTop: 45,
      "--reactour-accent": "#2F7EB2",
    }),
    close: (base) => ({
      ...base,
      width: 12,
      color: "gray",
    }),
    maskArea: (base) => ({
      ...base,
      rx: 10,
    }),
  },
  prevButton: ({ currentStep, setCurrentStep }) => {
    const first = currentStep === 0;
    return (
      <Button
        onClick={() => {
          if (!first) {
            setCurrentStep(currentStep - 1);
          }
        }}
        colorScheme="brand"
        variant="outline"
        visibility={first ? "hidden" : "visible"}
      >
        Indietro
      </Button>
    );
  },
  nextButton: ({ currentStep, setCurrentStep, stepsLength, setIsOpen }) => {
    const last = currentStep === stepsLength - 1;
    return (
      <Button
        onClick={() => {
          if (last) {
            setIsOpen(false);
          } else {
            setCurrentStep(currentStep + 1);
          }
        }}
        colorScheme="brand"
        variant="solid"
      >
        {last ? "Fine" : "Avanti"}
      </Button>
    );
  },
};

export default {
  steps,
  tourOptions,
  // ReactTourOptions,
};
