type TourContent = { title: string; text: string };
export const tourCopy = {
  welcome: {
    title: "Benvenuto in Mio Trentino Business",
    text: "Questo tutorial ti guiderà nell’utilizzo della piattaforma di gestione degli orari di apertura e chiusura della tua attività.",
  },
  manual_tab: {
    title: "Stato manuale",
    text: "Da qui puoi verificare lo stato di apertura/chiusura della tua attività, e procedere con la chiusura temporanea.",
  },
  current_status: {
    title: "Stato attuale",
    text: "Verifica l’informazione di apertura impostata da calendario per la data odierna. In caso di imprevisti procedi con la chiusura temporanea.",
  },
  closing_modal: {
    title: "Chiusura temporanea",
    text: "Puoi chiudere l’attività tutto il giorno, oppure qualche ora, motivando la chiusura. Passata la mezzanotte tornerà valida l’informazione inserita a calendario.",
  },
  calendar_tab: {
    title: "Calendario",
    text: "Da qui puoi aggiornare gli orari di apertura e chiusura del calendario. Puoi lavorare sul singolo giorno, la singola settimana o un periodo di tempo specificato.",
  },
  weekday_edit_btn: {
    title: "Modifica giorno",
    text: "Per ogni giorno puoi specificare l’orario di apertura oppure indicare che la tua attività è chiusa. Puoi lasciare “Non impostato” se non sei ancora certo del tuo orario.",
  },
  daypage_hours: {
    title: "Fasce orarie",
    text: "Indica qui una o più fasce orarie di apertura.",
  },
  daypage_close: {
    title: "Chiusura",
    text: "Seleziona “Chiudi” per indicare che la tua attività è chiusa per l’intera giornata.",
  },
  // daypage_open: {
  //   title: "Apri attività",
  //   text: "Se l'attività è impostata come chiusa usa questo pulsante per aprirla e impostare gli orari.",
  // },
  // daypage_save: {
  //   title: "Salva modifiche",
  //   text: "Una volta impostato il nuovo orario, salva o annulla le modifiche.",
  // },
  weekday_checkbox: {
    title: "Selezione giorni",
    text: "Seleziona un giorno per accedere alle funzioni di copia.",
  },
  weekday_copy_buttons: {
    title: "Copia su periodo o settimana",
    text: "Puoi copiare l’orario sulla settimana oppure su un periodo.",
  },
  weekday_copy_weekday: {
    title: "Copia su giorno della settimana",
    text: "Seleziona i giorni della settimana su cui copiare l’orario.",
  },
  weekday_copy_period: {
    title: "Copia su periodo",
    text: "Seleziona un periodo su cui copiare l’orario. Puoi copiare su periodo anche più giorni o l’intera settimana.",
  },
  end: {
    title: "Grazie",
    text: "Questo è tutto, grazie per l’attenzione e buon utilizzo! Per assistenza e informazioni contatta la tua Apt.",
  },
} as const satisfies Record<string, TourContent>;
