import { ShepherdTour, Tour, Step } from "react-shepherd";
import { offset } from "@floating-ui/dom";

type Steps = React.ComponentProps<typeof ShepherdTour>["steps"];
type ShowEvent = {
  step: Step;
  previous: Step;
  tour: Tour;
};

const clickElement = (selector: string) => {
  return async () => {
    const element = document.querySelector(selector);
    if (element) {
      (element as HTMLElement).click();
    }
  };
};

const steps = (t: Tour): Steps => {
  t.on("complete", () => {
    clickElement(".status-tab")();
  });
  t.on("cancel", () => {
    clickElement(".status-tab")();
  });
  const exitButton: Step.StepOptionsButton = {
    classes: "shepherd-button-secondary",
    text: "Exit",
    action: t.cancel,
  };
  const previousButton: Step.StepOptionsButton = {
    classes: "shepherd-button-secondary",
    text: "Back",
    action: t.back,
  };
  const nextButton: Step.StepOptionsButton = {
    classes: "shepherd-button-primary",
    text: "Next",
    action: t.next,
  };
  return [
    {
      id: "welcome",
      buttons: [exitButton, nextButton],
      title: "Benvenuto",
      text: `
      <div style="display:flex;gap:15px;flex-direction:column">
        <img
        src="https://imgv3.fotor.com/images/slider-image/a-man-holding-a-camera-with-image-filter.jpg"
        width="100%"
        height="100%"
        />
      Questo tutorial ti guiderà nell'utilizzo della schermata di gestione
      di un POI
      </div>
      `,
    },
    {
      id: "email",
      attachTo: { element: "#email-edit", on: "bottom" },
      buttons: [previousButton, nextButton],
      title: "Modifica email",
      text: ["Clicca qui per modificare l'email del POI"],
    },
    {
      id: "tab1",
      attachTo: { element: ".status-tab", on: "bottom" },
      buttons: [previousButton, nextButton],
      title: "Scheda stato",
      text: [
        "La scheda stato permette di visualizzare lo stato di apertura del POI",
      ],
      beforeShowPromise: clickElement(".status-tab"),
    },
    {
      id: "tab2",
      attachTo: { element: ".calendar-tab", on: "bottom" },
      buttons: [previousButton, nextButton],
      title: "Scheda calendario",
      text: [
        "La scheda calendario permette di visualizzare il calendario di apertura del POI",
      ],
      beforeShowPromise: clickElement(".calendar-tab"),
    },
    {
      id: "tab3",
      attachTo: { element: ".operator-tab", on: "bottom" },
      buttons: [previousButton, nextButton],
      title: "Scheda operatore",
      text: [
        "La scheda operatore permette di visualizzare i dati dell'operatore del POI",
      ],
      beforeShowPromise: clickElement(".operator-tab"),
    },
    {
      id: "end",
      buttons: [previousButton, exitButton],
      title: "Fine",
      text: [
        "<p>Questo è tutto, buon utilizzo!</p><p>Per ulteriori informazioni contattataci</p>",
      ],
    },
  ];
};

const tourOptions: React.ComponentProps<typeof ShepherdTour>["tourOptions"] = {
  defaultStepOptions: {
    floatingUIOptions: {
      middleware: [offset(10)],
    },
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

export default { steps, tourOptions };
