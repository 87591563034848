import { gql } from "@apollo/client";

/*
{
  "userLogged": {
    "_id": "63af0220f4eb4e12c16f10b9"
  },
  "auth": {
    "isActive": true,
    "isEmailVerified": true,
    "permission": {
      "scopes": [
        "base",
        "admin"
      ]
    },
    "lastLogin": "2023-01-01T22:58:12.074Z",
    "area": "apt-madonna-di-campiglio",
    "skiArea": "MDC"
  },
  "iat": 1672613893,
  "exp": 1672700293
}
*/

export type APP_STATE_PROPS = {
  user: string | null;
  skiarea: string | null;
  area: string | null;
  scopes: string[] | null;
  secure: string | null;
  secureInfos: string | null;
};

export const APP_STATE = gql`
  query appState {
    user @client
    scopes @client
    skiarea @client
    area @client
    secure @client
    secureInfos @client
  }
`;
