// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Circle,
} from "@chakra-ui/react";
// Custom Components

import { SearchBar } from "./Searchbar/SearchBar";
import { SidebarResponsive } from "../Sidebar/Sidebar";
import { MdNotificationsNone } from "react-icons/md";
import { routes } from "routes";

import { useParams } from "react-router-dom";
import { logout } from "libs/apollo/auth";
import { HamburgerIcon, SettingsIcon } from "@chakra-ui/icons";
import theme from "theme/theme";
import { ENV } from "config";

export default function HeaderLinks() {
  const navbarIcon = useColorModeValue("gray.400", "white");
  const menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      {/* <SearchBar /> */}

      <SidebarResponsive />

      <Menu>
        <MenuButton p="0px">
          <Circle bg={theme.colors.tgpBlue} size={30}>
            <SettingsIcon color="white" />
          </Circle>

          {/*       <Avatar
            ml="12px"
            _hover={{ cursor: "pointer" }}
            color="white"
            name={"Admin"}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          /> */}
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          {/* <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, Admin User
            </Text>
          </Flex> */}
          <Flex flexDirection="column" p="10px">
            <MenuItem>
              <Text fontSize="xs">Versione {ENV.deviceInfo.app.version}</Text>
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout();
              }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Logout</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
