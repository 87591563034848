import { useToast } from "@chakra-ui/react";
import { DEFAULT_ERROR, __DEV__ } from "config";
import { useApolloNetworkStatus } from "libs/apollo/createClient";
import { useEffect } from "react";
import { consoleTransport } from "react-native-logs";

let error = {
  name: "ServerError",
  response: {},
  statusCode: 400,
  result: {
    errors: [
      {
        message:
          'Variable "$skiarea" of required type "SkiArea!" was not provided.',
        locations: [
          {
            line: 91,
            column: 23,
          },
        ],
        extensions: {
          code: "BAD_USER_INPUT",
          exception: {
            stacktrace: [
              'GraphQLError: Variable "$skiarea" of required type "SkiArea!" was not provided.',
              "    at coerceVariableValues (/var/www/html/mio-trentino-business-backend/node_modules/graphql/execution/values.js:105:11)",
              "    at getVariableValues (/var/www/html/mio-trentino-business-backend/node_modules/graphql/execution/values.js:45:21)",
              "    at buildExecutionContext (/var/www/html/mio-trentino-business-backend/node_modules/graphql/execution/execute.js:280:63)",
              "    at execute (/var/www/html/mio-trentino-business-backend/node_modules/graphql/execution/execute.js:116:22)",
              "    at execute (/var/www/html/mio-trentino-business-backend/node_modules/apollo-server-core/src/requestPipeline.ts:501:34)",
              "    at processGraphQLRequest (/var/www/html/mio-trentino-business-backend/node_modules/apollo-server-core/src/requestPipeline.ts:407:28)",
              "    at processTicksAndRejections (node:internal/process/task_queues:95:5)",
              "    at processHTTPRequest (/var/www/html/mio-trentino-business-backend/node_modules/apollo-server-core/src/runHttpQuery.ts:436:24)",
            ],
          },
        },
      },
    ],
  },
};

const errorParser = (err: any): { msg: string; desc: string } => {
  let msg = DEFAULT_ERROR;
  let desc = null;

  //if (!__DEV__) return { msg, desc };
  if (typeof err === "string") return { msg, desc: err };

  if (err?.operation?.operationName) {
    msg = "Error on " + err?.operation?.operationName;
  }

  if (err?.graphQLErrors?.length) {
    desc = err.graphQLErrors[0].message;
    return { msg, desc };
  }

  if (err?.networkError?.result?.errors?.length) {
    desc = err?.networkError?.result?.errors[0]?.message;
    return { msg, desc };
  }

  if (err?.message) return { msg, desc: err?.message };
  return { msg, desc: JSON.stringify(err) };
};

const ErrorHelper = () => {
  const status = useApolloNetworkStatus();
  const toast = useToast();

  useEffect(() => {
    if (status.mutationError) {
      let err = errorParser(status.mutationError);
      if (err.desc !== "jwt expired") {
        //toast.closeAll();
        toast({
          title: `${err.msg}`,
          description: err.desc,
          status: "error",
          isClosable: true,
        });
      }
    }
  }, [status.mutationError]);

  useEffect(() => {
    if (status.queryError) {
      //toast.closeAll();
      let err = errorParser(status.queryError);
      if (err.desc !== "jwt expired") {
        toast({
          title: `${err.msg}`,
          description: err.desc,
          status: "error",
          isClosable: true,
          containerStyle: { maxHeight: "200px" },
        });
      }
    }
  }, [status.queryError]);

  return <></>;
};

export { ErrorHelper };
