import React from "react";

export type MowiIcons =
  | "2d_view"
  | "2d"
  | "3d_view"
  | "3d"
  | "aerialway_chair_lift"
  | "aerialway_gondola"
  | "aerialway_lift_go_down"
  | "aerialway_lift_go_up"
  | "aerialway_station"
  | "aerialway"
  | "alert"
  | "alpine_hut_sponsored"
  | "alpine_hut"
  | "alpine_skiing"
  | "alpineskiing_gear"
  | "andalo_life"
  | "animals_no"
  | "animals_yes"
  | "artificial_card"
  | "atm"
  | "attention"
  | "baby_backpack"
  | "baby_trail"
  | "bicycle_bus_stop"
  | "bicycle_facilities"
  | "bicycle_info"
  | "bicycle_rent"
  | "bicycle_repair_sponsored"
  | "bicycle_repair"
  | "bicycle_sell"
  | "bicycle_wash"
  | "bike_shops"
  | "bus_stop"
  | "bus"
  | "cableway_trail"
  | "cam_ar"
  | "charging_station"
  | "check_encircled_thin"
  | "check_medium"
  | "check_thin"
  | "chevron_down"
  | "chevron_left"
  | "chevron_right"
  | "chevron_up"
  | "circle_trail"
  | "close_encircled_thin"
  | "close_thin"
  | "close"
  | "compass"
  | "crampons"
  | "crosscountry_skiing_gear"
  | "crosscountry_skiing"
  | "difficulty_trail"
  | "disabled_instructor"
  | "discover"
  | "doctor"
  | "down"
  | "downhill_trail"
  | "duration_trail"
  | "eat_and_sleep"
  | "elevation_profile_flat"
  | "eat_sleep"
  | "elevation_profile_outline"
  | "emotion"
  | "exp_carriage"
  | "exp_fat_bike"
  | "exp_freeride"
  | "exp_ice_climbing"
  | "exp_paragliding"
  | "exp_snow_shoes"
  | "exp_snowcat"
  | "exp_snowscoot"
  | "exp_strider_bike"
  | "exp_trekking"
  | "family_trail"
  | "filtri_menu_tab"
  | "finish_trail"
  | "first_steps"
  | "food"
  | "fountain"
  | "friends"
  | "go"
  | "googles_and_mask"
  | "grid_view"
  | "group_outing_2"
  | "group_outing_fullcolor"
  | "group_outing_outline"
  | "group_outing"
  | "gym"
  | "handicap_yes"
  | "helmet"
  | "historical_point"
  | "horizontal_view"
  | "ice_skating"
  | "info_point_outdoor"
  | "info_point_panel_ar"
  | "info_point_panel"
  | "info_point_sponsored"
  | "info_point"
  | "instructor_meeting_point"
  | "kart"
  | "kids_trail"
  | "kinderclub"
  | "landscape_trail"
  | "left"
  | "legend"
  | "lenght_card"
  | "length"
  | "like"
  | "live_area"
  | "location_trail"
  | "locator"
  | "login_community"
  | "login_live_track"
  | "login"
  | "logout"
  | "low"
  | "max_slope"
  | "menu"
  | "modify"
  | "more_horizontal"
  | "more_vertical"
  | "mowi"
  | "my_hotel"
  | "natural_card"
  | "new_friend"
  | "nhd_card"
  | "outdoor_school_sponsored"
  | "outdoor_school"
  | "panorama"
  | "parking"
  | "party"
  | "patrol_alert"
  | "patrol_archive"
  | "patrol_management_section_standby"
  | "patrol_management_section_trail_check"
  | "patrol_management_warning"
  | "patrol_symbol"
  | "patrol_top_bar_tacking_on"
  | "patrol_top_bar_trail_status"
  | "patrol_top_bar_warning_list"
  | "pedestrians_no"
  | "pedestrians_yes"
  | "period_trail"
  | "pharmacy"
  | "phd_card"
  | "plus_encircled_thin"
  | "plus_thin"
  | "point_danger"
  | "point_forbidden"
  | "point_warning"
  | "profile"
  | "pub_sponsored"
  | "pub"
  | "public_utility"
  | "record_activities"
  | "rent_sponsored"
  | "rent"
  | "repair_kit"
  | "report_abuse"
  | "restaurant"
  | "review_comment"
  | "review_like"
  | "review_profile"
  | "riding_school"
  | "right"
  | "school_sponsored"
  | "school"
  | "schoolcamp"
  | "search_in_map_2"
  | "search_in_map"
  | "search_trail"
  | "search"
  | "sell"
  | "settings"
  | "shop_bicycle"
  | "shop_pass"
  | "shop_sponsored"
  | "shop_ticket"
  | "shop"
  | "shower"
  | "ski_boots"
  | "ski_bus_stop"
  | "ski_info"
  | "ski_instructor"
  | "ski_lift"
  | "ski_school"
  | "ski_sell"
  | "ski_shops"
  | "ski_storage"
  | "skill_area"
  | "skimo_gear"
  | "skimo"
  | "sleeding_gear"
  | "sleeding"
  | "sleep"
  | "snow_clothing"
  | "snow_park"
  | "snow_shoes_gear"
  | "snow_shoes"
  | "snow_tubing"
  | "snowboard_gear"
  | "snowboard_instructor"
  | "snowboard"
  | "snowscoot_gear"
  | "snowscoot"
  | "spa"
  | "standard"
  | "start"
  | "strong"
  | "swimming_pool"
  | "tapis_roulant"
  | "th_card"
  | "toilette"
  | "top_trail"
  | "tourist_attraction"
  | "track_center"
  | "track_pump"
  | "trails_alert"
  | "train_stop"
  | "train"
  | "transfer"
  | "trash"
  | "trekking"
  | "tutorial_bike_park"
  | "tutorial_covid"
  | "tutorial_cross_country"
  | "tutorial_family_snow"
  | "tutorial_family"
  | "tutorial_gesture_nav"
  | "tutorial_gesture_rotation"
  | "tutorial_gesture_zoom"
  | "tutorial_nature"
  | "tutorial_outdoor"
  | "tutorial_respect"
  | "tutorial_ski"
  | "unlike"
  | "up"
  | "uphill_trail"
  | "vertical_view"
  | "weather"
  | "technical_difficulty";

interface IconProps {
  name: MowiIcons;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
  className?: string;
}

const MowiIcon = (props: IconProps) => {
  if (!props.name) return null;
  let size = 20;
  if (props.size) {
    size = props.size;
  }

  let color = props.color;

  return (
    <i
      className={"icon-" + props.name + " " + props.className || ""}
      style={{ color, fontSize: size, ...props.style }}
    />
  );
};

export { MowiIcon };
