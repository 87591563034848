// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Button,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import AdminNavbarLinks from "./NavbarLinksAdmin";

import { CgArrowLeft } from "react-icons/cg";

interface AdminNavbarProps {
  secondary?: string;
  fixed?: boolean;
  message?: string;
  brandText?: string;
  onOpen?: () => void;
}

export default function AdminNavbar({
  secondary,
  onOpen,
  fixed,
  message,
  brandText,
}: AdminNavbarProps) {
  const secondaryText = useColorModeValue("gray.700", "white");
  const navigate = useNavigate();
  const secondaryMargin = "0px";
  const paddingX = "15px";
  const gap = "0px";

  const { pathname } = useLocation();
  const id = pathname.split("/")[2];
  const root = pathname.split("/")[1];

  const BreadCrumbs = () =>
    pathname
      .split("/")
      .slice(1)
      .map((p, i) => (
        <BreadcrumbItem key={p} color={secondaryText} fontSize="sm" mb="5px">
          <NavLink
            to={
              "/" +
              pathname
                .split("/")
                .slice(1, i + 2)
                .join("/")
            }
          >
            <Text textTransform="capitalize" color={secondaryText}>
              {i == 1 ? "Modifica" : p}
            </Text>
          </NavLink>
        </BreadcrumbItem>
      ));

  return (
    <Box
      zIndex="100"
      position="fixed"
      bg={useColorModeValue("whiteAlpha.500", "rgba(11,20,55,0.5)")}
      backdropFilter="blur(20px)"
      borderRadius="16px"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top={{ base: "12px", md: "16px", xl: "18px" }}
      w={{
        base: "calc(100% - 6%)",
        md: "calc(100% - 8%)",
        lg: "calc(100% - 6%)",
        xl: "calc(100% - 360px)",
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        alignItems={{ xl: "center" }}
        mb={gap}
      >
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb>
            <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
              <NavLink to="/">Home</NavLink>
            </BreadcrumbItem>
            {BreadCrumbs()}
          </Breadcrumb>

          <Flex gap="16px" direction="column" alignItems="flex-start">
            <Text
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              textTransform={"capitalize"}
            >
              {root || "Home"}
            </Text>
            <Button
              variant="link"
              leftIcon={<CgArrowLeft />}
              onClick={() => navigate(-1)}
            >
              Indietro
            </Button>
          </Flex>
        </Box>
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <AdminNavbarLinks />
        </Box>
      </Flex>
    </Box>
  );
}
