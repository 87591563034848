import {
  Flex,
  Text,
  Divider,
  Image,
  VStack,
  Box,
  HStack,
  IconButton,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { ENV } from "config";
import { NavLink } from "react-router-dom";

import LogoColor from "../../../assets/img/logo-bianco.png";
import { SideBarLink } from "./Links";
import { checkScope } from "libs/apollo/auth";
import { APTSelector } from "./APTSelector";
import React from "react";
import { ACTUAL_AUTH } from "stores/db/auth";
import { ETgpArea } from "stores/queries/area";
import { CgExport } from "react-icons/cg";
import axios from "axios";

const SidebarBrand = () => {
  const [exportLoading, setExportLoading] = React.useState(false);

  const [area, setArea] = React.useState<ETgpArea | undefined>(
    ACTUAL_AUTH.area as ETgpArea
  );

  const areaName = React.useMemo(
    () =>
      Object.keys(ETgpArea).find(
        (key) => (ETgpArea as any)[key] === ACTUAL_AUTH.area
      ),
    [ACTUAL_AUTH.area]
  );
  return (
    <>
      <Flex align="center" direction="column" bg="tgpBlue" pt={5}>
        <NavLink to="/areas">
          <Flex w="100%" align="center" mb="12px" direction={"column"}>
            <Image src={LogoColor} width={180} mb={2} />
            <Text
              fontSize="17px"
              opacity={0.9}
              fontWeight="medium"
              color="white"
            >
              {ENV.deviceInfo.app.name}
            </Text>
            {/* <Text fontSize="12px" opacity={0.5}>
            {ENV.deviceInfo.app.version}
          </Text> */}
          </Flex>
        </NavLink>
        <Divider />
      </Flex>
      {checkScope("root") ? (
        <Box>
          <VStack
            align="start"
            bg="gray.100"
            borderBottomWidth={2}
            pb={2}
            pt={5}
          >
            <HStack
              w="100%"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <SideBarLink
                key={"sidebarlink_root_1"}
                route={{
                  name: "Tutte le aree",
                  path: "/overview",
                  icon: "menu",
                }}
              />
              <Tooltip label="Esporta Dati">
                <IconButton
                  cursor={"pointer"}
                  onClick={() => {
                    if (exportLoading) return;
                    setExportLoading(true);
                    axios({
                      url: ENV.backend.api + `/poi/rootStatisticsAll/`,
                      method: "GET",
                      responseType: "blob",
                      headers: {
                        Authorization: "Bearer " + ACTUAL_AUTH.accessToken,
                      },
                    }).then((response) => {
                      setExportLoading(false);
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        "allareas_" + new Date().toLocaleDateString() + ".csv"
                      );
                      document.body.appendChild(link);
                      link.click();
                    });
                  }}
                  mb={2}
                  size={"sm"}
                  mr={4}
                  icon={exportLoading ? <Spinner size={"sm"} /> : <CgExport />}
                  aria-label="Export"
                />
              </Tooltip>
            </HStack>

            <SideBarLink
              key={"sidebarlink_root_2"}
              route={{
                name: "Gestione utenti",
                path: "/users",
                icon: "group_outing_fullcolor",
              }}
            />
          </VStack>
          <Box mt="4">
            <APTSelector area={area} setArea={setArea} />
          </Box>
        </Box>
      ) : (
        <VStack p={5} /* align="start" */ bg="gray.100" borderBottomWidth={2}>
          <Text color={"tgpBlue"} fontWeight={"bold"}>
            A.P.T. {areaName}
          </Text>
        </VStack>
      )}
    </>
  );
};

export default SidebarBrand;
