import { makeVar } from "@apollo/client";
import { APP_STATE_PROPS } from "stores/queries/appstate";

const user = makeVar<APP_STATE_PROPS["user"]>(null);
const scopes = makeVar<APP_STATE_PROPS["scopes"]>(null);
const skiarea = makeVar<APP_STATE_PROPS["skiarea"]>(null);
const area = makeVar<APP_STATE_PROPS["area"]>(null);
const secure = makeVar<APP_STATE_PROPS["secure"]>(null);
const secureInfos = makeVar<APP_STATE_PROPS["secureInfos"]>(null);

export { user, scopes, skiarea, area, secure, secureInfos };
