import React from "react";

import { Hide, Stack, Text, VStack } from "@chakra-ui/react";

import Card from "ui/Card";
import { secure } from "stores/db/appstate";
import { Resource } from "stores/queries/area";

type LiveStatusProps = {
  manual: Resource["statuses"]["manual"];
  live: Resource["statuses"]["live"];
  _id: string;
};

const LiveStatus = (props: LiveStatusProps) => {
  const status = props.live;

  const isLocked = status?.updatedOrigin === "EXTERNAL";
  const managed = typeof status?.status === "boolean";
  const isManual = status?.updatedOrigin === "MANUAL";

  let color = "gray";
  let txt = "nessuna";
  let statusTxt = "nessuno";

  if (managed) {
    if (status?.status) {
      color = "green";
      statusTxt = "aperto";
    } else {
      color = "pink";
      statusTxt = "chiuso";
    }
  }

  if (status?.updatedOrigin === "CALENDAR") txt = "calendario";
  else if (status?.updatedOrigin === "EXTERNAL") txt = "sorgente esterna";
  else if (
    status?.updatedOrigin === "MANUAL" &&
    typeof props.manual?.status === "boolean"
  ) {
    txt = "impostazione manuale";
  }

  return (
    <VStack w="100%" mt={5}>
      <Stack
        direction={{ base: "column", lg: "row", xl: "row" }}
        w="100%"
        justifyContent={"center"}
      >
        {!secure() && (
          <Hide below="md">
            <VStack alignItems={"flex-end"}>
              <Text
                textAlign={"right"}
                width={"230px"}
                fontSize={"2xl"}
                fontWeight={"bold"}
                mr={2}
                mt={1}
              >
                Stato Attuale:
              </Text>
            </VStack>
          </Hide>
        )}
        <VStack flex={1}>
          <Card
            p={1}
            flex={1}
            bgColor={color + ".50"}
            borderWidth={1}
            borderColor={color + ".500"}
          >
            <Text
              textAlign={"center"}
              color={color + ".500"}
              fontSize={"2xl"}
              fontWeight={"bold"}
              textTransform={"uppercase"}
              w="100%"
            >
              {statusTxt}
            </Text>
          </Card>
          <Text
            textAlign={"center"}
            color={"gray.700"}
            fontSize={"md"}
            w="100%"
            /* textTransform={"uppercase"} */
          >
            Fonte: <b>{txt}</b>
          </Text>
        </VStack>

        {/*         {Btns(status?.status)} */}
      </Stack>
      {/* <Stack
        direction={{ base: "column", lg: "row", xl: "row" }}
        w="100%"
        alignItems="center"
        justifyContent={"center"}
      >
        <Hide below="md">
          <VStack alignItems={"flex-end"} mr={2}>
            <Text textAlign={"right"} width={"230px"} fontSize={"xl"}>
              Fonte
            </Text>
          </VStack>
        </Hide>

        <Card p={1} flex={1} bgColor={"white"} borderWidth={1}>
          <Text
            textAlign={"center"}
            color={"gray.700"}
            fontSize={"xl"}
            w="100%"
            textTransform={"uppercase"}
          >
            {txt}
          </Text>
        </Card>
      </Stack> */}
      {/*       {status?.motivation[0]?.value ? (
        <Stack direction={{ base: "column", lg: "row", xl: "row" }}>
          <Box width={"230px"} />
          <Text flex={1} textAlign={"center"}>
            <b>MSG:</b> {status.motivation[0].value}
          </Text>
          <Box width={"120px"} />
        </Stack>
      ) : null} */}
      {/*  {!isCalendar && !isLocked && !managed ? (
        <Stack
          alignItems={"center"}
          direction={{ base: "column", lg: "row", xl: "row" }}
        >
          <Box width={"230px"} />
          {OpenBtn} {CloseBtn}
        </Stack>
      ) : null} */}
    </VStack>
  );
};

export { LiveStatus };
