import { makeVar } from "@apollo/client";

type ReactSelectOption = {
  value: string;
  label: string;
};

type PoiFilters = {
  search: string;
  category: readonly ReactSelectOption[];
};

export const STORED_POI_FILTERS = {
  search: makeVar<PoiFilters["search"]>(""),
  category: makeVar<PoiFilters["category"]>([]),
};
