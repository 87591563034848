import {
  Badge,
  Button,
  Card,
  Checkbox,
  Circle,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Hide,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Switch,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
  VStack,
  Select,
  AlertIcon,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import styled from "@emotion/styled";

import { ETgpArea } from "stores/queries/area";
import theme from "theme/theme";
import { MowiIcon } from "ui/MowiIcon/Icon";
import { SearchBar } from "components/List/SearchBar";
import { useMutation, useQuery } from "@apollo/client";
import {
  USERS,
  USER_CREATE,
  USER_DELETE,
  USER_UPDATE,
} from "stores/queries/users";
import { User } from "libs/apollo/__generated__/graphql";
import { FaPlus } from "react-icons/fa";
import { checkScope } from "libs/apollo/auth";

type ReactSelectOption = {
  value: string;
  label: string;
};

type TableRow = {
  _id: string;
  name: string;
  email: string;
  role: string;
  area: string;
  areaName: string;
};

const tableColumn = [
  {
    Header: "ID",
    accessor: "_id",
    disableSortBy: true,
  },
  {
    Header: "NOME",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "RUOLO",
    accessor: "role",
  },
  {
    Header: "APT",
    accessor: "areaName",
  },
  {
    Header: "OPERAZIONI",
    disableSortBy: true,
  },
] as const satisfies readonly Column<TableRow>[];

export const StyleWrapper = styled.div`
  .basic-multi-select {
    display: flex;
    flex: 1;
    margin-right:10,
    margin-left:10,
    font-family: Roboto
    overflow: hidden;
  }
  .select__control--is-focused {
    background-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px !important;
    border-radius: 20px;
    box-shadow: 0 0 0 0px rgb(244, 247, 254);
  }

  .select__control--menu-is-open {
    background-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px !important;
    border-radius: 20px;
  }

  .select__control {
    width:100%;
    background-color: rgb(244, 247, 254);
    border-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px;
    border-radius: 20px;
  }
  .select__menu {
    z-index:99999999
  }

  .select__multi-value {
    background-color: ${theme.colors.gray[400]};
    border-radius:10px;
    overflow: hidden;
  }
  .select__multi-value__label, .select__multi-value__remove {
    color: #fff
  }

  .select__option {
    font-size: 14px;
  }

  .select__placeholder {
    color: ${theme.colors.gray[400]} !important;
  } 
`;

const HIDDEN_COLS: string[] = ["ID"];

const UserTable = () => {
  const { loading, data } = useQuery<{ rootUsers: User[] }>(USERS, {
    skip: !checkScope("root"),
  });
  const [updateUser, { loading: loadingUpdate }] = useMutation(USER_UPDATE, {
    refetchQueries: [USERS],
    awaitRefetchQueries: true,
  });
  const [createUser, { loading: loadingCreate }] = useMutation(USER_CREATE, {
    refetchQueries: [USERS],
    awaitRefetchQueries: true,
  });
  const [deleteUser, { loading: loadingDelete }] = useMutation(USER_DELETE, {
    refetchQueries: [USERS],
    awaitRefetchQueries: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDel,
    onOpen: onOpenDel,
    onClose: onCloseDel,
  } = useDisclosure();

  const [selectedUser, setSelectedUser] = useState<TableRow>(null);

  const toast = useToast();
  const successToast = (message = "Operazione completata") =>
    toast({
      title: message,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  const errorToast = (message = "Operazione fallita") =>
    toast({
      title: message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });

  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [strSearch, setStrSearch] = useState("");

  const searchTimeout = useRef(null);
  const onSearchChange = (search: string) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(() => {
      setStrSearch(search);
    }, 300);
  };

  useEffect(() => {
    if (!data?.rootUsers) return;

    let resourcesArr: TableRow[] = [];
    let users = data?.rootUsers;

    for (let i = 0; i < users.length; i++) {
      const user = users[i];

      let role = "BASE";

      if ((user.auth.permission.scopes as string[]).includes("root")) {
        role = "ROOT";
      } else if ((user.auth.permission.scopes as string[]).includes("admin")) {
        role = "APT";
      }

      let areaName = "-";

      if (role !== "ROOT") {
        Object.keys(ETgpArea).map((key) => {
          if (user.auth.area === (ETgpArea as any)[key]) {
            areaName = key;
          }
        });
      }

      if (
        strSearch?.length > 3 &&
        !user.displayName.toLowerCase().includes(strSearch) &&
        !user.email.toLowerCase().includes(strSearch) &&
        !user.auth?.area?.includes(strSearch)
      ) {
        continue;
      }

      resourcesArr.push({
        _id: user._id,
        name: user.displayName,
        email: user.email,
        role,
        area: user.auth.area,
        areaName,
      });
    }

    setTableData(resourcesArr);
  }, [data, strSearch]);

  const tableInstance = useTable<TableRow>(
    {
      columns: tableColumn,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
  } = tableInstance;
  initialState.pageSize = 10;

  const hiddenColSM = ["EMAIL", "RUOLO"];

  const { pageIndex, pageSize } = state;

  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        overflowY={"hidden"}
        paddingBottom={"0px !important"}
        bg={"white"}
        borderRadius={30}
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      >
        <Flex px="25px" justify="space-between" mt={5} mb={5} align="center">
          <Flex align="center" color={"brand.500"}>
            <MowiIcon name={"profile"} size={30} />
            <Text
              color={"gray.700"}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Lista Utenti
            </Text>
          </Flex>
          <HStack>
            <SearchBar
              value={strSearch}
              setSearch={onSearchChange}
              transform={(str) => str.toLowerCase()}
            />
            <Button
              colorScheme={"brand"}
              size={"sm"}
              //variant="outline"
              onClick={() => {
                setSelectedUser(null);
                onOpen();
                //window.open(cell.value[1], "_blank");
              }}
            >
              <FaPlus
                fontSize={9}
                color={"white"}
                style={{ marginRight: "5px" }}
              />{" "}
              Crea utente
            </Button>
          </HStack>

          {/*  <Menu /> */}
        </Flex>
        <Flex maxHeight={"calc(100vh - 320px)"} overflowY={"auto"}>
          <Table {...getTableProps()} variant="striped">
            <Thead bgColor={"white"} position="sticky" top={0} zIndex="docked">
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) =>
                    HIDDEN_COLS.includes(
                      column.Header as string
                    ) ? undefined : (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        pe="10px"
                        key={index}
                        borderColor={"gray.200"}
                        className={
                          hiddenColSM.includes(String(column.Header))
                            ? "hidden-sm"
                            : undefined
                        }
                        style={{
                          display: index === 0 ? "none" : undefined,
                        }}
                      >
                        <Flex
                          justify="flex-start"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {column.render("Header")}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " ↓"
                              : " ↑"
                            : ""}
                        </Flex>
                      </Th>
                    )
                  )}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data;
                      if (HIDDEN_COLS.includes(cell.column.Header as string))
                        return null;
                      if (cell.column.Header === "NOME") {
                        //const role = row.cells[3].value;
                        data = (
                          <Flex
                            direction={"row"}
                            align="center"
                            onClick={() => {
                              setSelectedUser(row.original);
                              onOpen();
                            }}
                            cursor={"pointer"}
                          >
                            <Text>{cell.value}</Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "RUOLO") {
                        let color = "yellow";
                        if (cell.value === "ROOT") color = "purple";
                        data = (
                          <Tag
                            width={"100%"}
                            textAlign={"center"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            size={"sm"}
                            variant="solid"
                            colorScheme={color}
                          >
                            {cell.value}
                          </Tag>
                        );
                      } else if (cell.column.Header === "OPERAZIONI") {
                        const role = row.cells[3].value;

                        data = (
                          <HStack>
                            <Tooltip label={"Modifica"}>
                              <Circle
                                fontSize={10}
                                size={7}
                                bg={"brand.500"}
                                onClick={() => {
                                  setSelectedUser(row.original);
                                  onOpen();
                                }}
                                cursor={"pointer"}
                              >
                                <EditIcon fontSize={12} color={"white"} />
                              </Circle>
                            </Tooltip>
                            <Tooltip label={"Elimina"}>
                              <Circle
                                fontSize={10}
                                size={7}
                                bg={"red.500"}
                                onClick={() => {
                                  setSelectedUser(row.original);
                                  onOpenDel();
                                }}
                                cursor={"pointer"}
                              >
                                <DeleteIcon fontSize={12} color={"white"} />
                              </Circle>
                            </Tooltip>
                          </HStack>
                        );
                      } else if (cell.column.Header === "ID") {
                        data = null;
                      } else {
                        data = (
                          <Text
                            fontSize="sm"
                            fontWeight="700"
                            whiteSpace="nowrap"
                          >
                            {cell.value}
                          </Text>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          py={
                            cell.column.Header === "OPERAZIONI"
                              ? "2"
                              : undefined
                          }
                          className={
                            hiddenColSM.includes(String(cell.column.Header))
                              ? "hidden-sm"
                              : null
                          }
                          key={index}
                          fontSize={{ sm: "14px" }}
                          //minW={{ sm: "10px",  md: "200px", lg: "auto" }}
                          borderColor="transparent"
                          style={{ display: index === 0 ? "none" : undefined }}
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          paddingX={5}
          style={{ borderTopWidth: 1 }}
          paddingY={1}
        >
          <Flex flexDirection={"row"}>
            <Button
              mx={2}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>{" "}
            <Button
              mx={2}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Precedente
            </Button>{" "}
            <Button mx={2} onClick={() => nextPage()} disabled={!canNextPage}>
              Prossima
            </Button>{" "}
            <Button
              mx={2}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Flex>{" "}
          <Badge width={120} mx={2}>
            Pagina{" "}
            <strong>
              {pageIndex + 1} di {pageOptions.length}
            </strong>{" "}
          </Badge>
        </Flex>
        {!loading && page.length < 1 && (
          <Flex
            w="100%"
            p={6}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Text>Nessun risultato soddisfa la tua richiesta</Text>
            <Button
              mt={6}
              onClick={() => {
                onSearchChange("");
              }}
            >
              Cancella filtro
            </Button>
          </Flex>
        )}
        {!tableData.length && loading ? (
          <>
            <Skeleton opacity={0.7} height={"60px"} mb={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} mt={1} mb={4} />
          </>
        ) : null}
      </Card>

      <Drawer
        isOpen={isOpen}
        size={"md"}
        placement="right"
        onClose={() => {
          setSelectedUser(null);
          onClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            color="white"
            zIndex="3"
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody px={5} py={5}>
            <Text fontWeight={"bold"} fontSize={30}>
              {selectedUser?._id ? "Modifica utente" : "Crea utente"}
            </Text>
            <VStack w="100%" alignItems="start" mt={5} spacing={5}>
              <FormControl key="name" id="name" isRequired>
                <FormLabel>Nome</FormLabel>
                <Input
                  placeholder="Nome"
                  borderRadius="16px"
                  focusBorderColor="tgpBlue"
                  value={selectedUser?.name || ""}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      name: e.target.value,
                    });
                  }}
                />
              </FormControl>
              <FormControl key="email" id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  focusBorderColor="tgpBlue"
                  placeholder="Email"
                  borderRadius="16px"
                  value={selectedUser?.email || ""}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      email: e.target.value,
                    });
                  }}
                />
              </FormControl>
              {selectedUser?.role !== "ROOT" ? (
                <FormControl id="area" mb={2} isRequired>
                  <FormLabel>A.P.T.</FormLabel>
                  <Select
                    placeholder="Seleziona APT"
                    value={selectedUser?.area || ""}
                    onChange={(e) => {
                      setSelectedUser({
                        ...selectedUser,
                        area: e.target.value,
                      });
                    }}
                    focusBorderColor="tgpBlue"
                    borderRadius={18}
                  >
                    {Object.keys(ETgpArea).map((key, index) => (
                      <option
                        style={{ color: "#010101" }}
                        key={"area_" + index}
                        value={(ETgpArea as any)[key]}
                      >
                        {key}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              <FormControl id="root" mb={2}>
                {/*        <FormLabel>A.P.T.</FormLabel> */}
                <Checkbox
                  checked={selectedUser?.role === "ROOT"}
                  isChecked={selectedUser?.role === "ROOT"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedUser({
                        ...selectedUser,
                        role: "ROOT",
                      });
                    } else {
                      setSelectedUser({
                        ...selectedUser,
                        role: "APT",
                      });
                    }
                  }}
                  colorScheme={"purple"}
                  //borderColor={"brand.600"}
                >
                  Assegna Permessi ROOT
                </Checkbox>
                {selectedUser?.role === "ROOT" && (
                  <FormLabel color={"red.500"} fontWeight={"bold"} mt={2}>
                    Attenzione, con questa opzione selezionata l'utente avrà
                    accesso alla modifica di tutte le A.P.T. e degli utenti!
                  </FormLabel>
                )}
              </FormControl>

              <HStack
                width={"100%"}
                align={"center"}
                justifyContent={"center"}
                mt={5}
              >
                <Button
                  colorScheme={"blackAlpha"}
                  size={"md"}
                  width={"40%"}
                  variant="outline"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Annulla
                </Button>
                <Button
                  colorScheme={"brand"}
                  size={"md"}
                  w={"40%"}
                  isDisabled={
                    !selectedUser?.name?.length ||
                    !selectedUser?.email?.length ||
                    (selectedUser.role !== "ROOT" &&
                      !selectedUser?.area?.length)
                  }
                  isLoading={loadingCreate || loadingUpdate}
                  //variant="outline"
                  onClick={() => {
                    if (!selectedUser) return;
                    const scopes = ["base", "admin"];
                    let adminArea = selectedUser.area;
                    if (selectedUser.role === "ROOT") {
                      scopes.push("root");
                      if (!selectedUser.area) adminArea = ETgpArea.Trento;
                    }
                    if (selectedUser?._id) {
                      updateUser({
                        variables: {
                          id: selectedUser._id,
                          user: {
                            email: selectedUser.email,
                            displayName: selectedUser.name,
                            scopes,
                            area: adminArea,
                          },
                        },
                      }).then((res) => {
                        if (res?.data?.rootUserUpdate?._id) {
                          successToast();
                        }
                      });
                      onClose();
                    } else {
                      createUser({
                        variables: {
                          user: {
                            email: selectedUser.email,
                            displayName: selectedUser.name,
                            scopes,
                            area: selectedUser.area,
                          },
                        },
                      }).then((res) => {
                        if (res?.data?.rootUserCreate?._id) {
                          successToast();
                        }
                      });
                      onClose();
                    }
                  }}
                >
                  Salva
                </Button>
              </HStack>
              {/* <FormControl id="area" mb={2}>
                <FormLabel>Ruolo</FormLabel>
                <Select
                  placeholder="Seleziona APT"
                  //value={area}
                  //onChange={handleSelectChange}
                  focusBorderColor="tgpBlue"
                  color={"tgpBlue"}
                  borderRadius={18}
                >
                  <option key={"option_1"} value={"apt"}>
                    {key}
                  </option>
                </Select>
              </FormControl> */}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal
        isOpen={isOpenDel}
        onClose={() => {
          setSelectedUser(null);
          onCloseDel();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody w={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Flex
              mt={4}
              px={2}
              w={"100%"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text fontSize={"lg"} mt={4} textAlign={"center"}>
                Stai per eliminare l'utete<br></br>
                <b>{selectedUser?.email}</b>
                <br></br>sei sicuro?
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent={"space-between"}>
            <Button
              w={"30%"}
              colorScheme="blackAlpha"
              variant={"outline"}
              onClick={() => {
                onCloseDel();
              }}
            >
              Annulla
            </Button>
            <Button
              colorScheme="red"
              w={"30%"}
              onClick={() => {
                deleteUser({ variables: { id: selectedUser._id } }).then(
                  (res) => {
                    if (res?.data?.rootUserDelete?.success) {
                      successToast();
                    }
                  }
                );
                onCloseDel();
              }}
            >
              Si, elimina
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export { UserTable };
