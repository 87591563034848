import { Box, FormControl, HStack, Select, Text } from "@chakra-ui/react";
import { changeArea } from "libs/apollo/auth";
import React from "react";
import { area } from "stores/db/appstate";
import { ETgpArea } from "stores/queries/area";
import { MowiIcon } from "ui/MowiIcon/Icon";

type Props = {
  area: ETgpArea;
  setArea: (area: ETgpArea) => void;
};

const APTSelector = (props: Props) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.setArea(event.target.value as ETgpArea);
    changeArea(event.target.value as ETgpArea)
      .then((res) => {
        if (res?.success) {
          window.location.reload();
        }
      })
      .then(() => {
        window.location.href = "/";
      });
  };
  return (
    <Box mb="1">
      <HStack
        px={5}
        align={"center"}
        width={"100%"}
        mb="1"
        color={"secondaryGray.800"}
      >
        <MowiIcon style={{ marginRight: 0 }} size={22} name={"search_in_map"} />{" "}
        <Text
          // color={"tgpBlue"}
          width={"100%"}
          textAlign={"start"}
          fontWeight={"bold"}
        >
          Seleziona A.P.T.
        </Text>
      </HStack>
      <FormControl px={5} id="area" mb={2}>
        <Select
          value={props.area}
          onChange={handleSelectChange}
          focusBorderColor="tgpBlue"
          color={"tgpBlue"}
        >
          {Object.keys(ETgpArea).map((key) => (
            <option
              style={{ color: "#010101" }}
              key={"option_" + (ETgpArea as any)[key]}
              value={(ETgpArea as any)[key]}
            >
              {key}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export { APTSelector };
