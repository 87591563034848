import React from "react";

import "./assets/css/App.css";
import "./assets/css/mowi-icons.css";
import "./assets/css/shepherd.css";

import "shepherd.js/dist/css/shepherd.css";

import { createRoot } from "react-dom/client";
import { App } from "app";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
