import React from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  POI,
  POIS_GET_QR_EMAIL,
  POIS_GET_QR_PDF,
  POIS_SECURE_MANAGE,
} from "stores/queries/pois";

import { Resource } from "stores/queries/area";
import { OperatorLink } from "components/Operator/OperatorLink";

interface PoiOperatorLinkProps {
  _id: string;
}

const PoiOperatorLink = (props: PoiOperatorLinkProps) => {
  const { loading, data } = useQuery<{ adminPoi: Resource }>(POI, {
    variables: { _id: props._id },
  });

  const [secureManage, { loading: smLoading }] = useMutation(
    POIS_SECURE_MANAGE,
    {
      refetchQueries: [{ query: POI, variables: { _id: props._id } }],
    }
  );

  const [getQrPdf, { loading: gqpLoading }] = useMutation(POIS_GET_QR_PDF);
  const [getQrEmail, { loading: gqeLoading }] = useMutation(POIS_GET_QR_EMAIL);

  return (
    <OperatorLink
      _id={props._id}
      type={"poi"}
      loading={loading || smLoading || gqpLoading || gqeLoading}
      resource={data?.adminPoi}
      secureManage={secureManage}
      getQrPdf={getQrPdf}
      getQrEmail={getQrEmail}
    />
  );
};

export { PoiOperatorLink };
