import { graphql } from "libs/apollo/__generated__";
import { gql } from "@apollo/client";

const USERS = gql(`
  query rootUsers {
    rootUsers {
      _id
      email
      displayName
      auth {
        createdAt
        createdWith
        isActive
        isEmailVerified
        loginCode {
          code
          expirationDate
        }
        permission {
          scopes
        }
        lastLogin {
          updatedAt
        }
        area
        skiArea
      }
    }
  }
`);

const USER_UPDATE = gql(`
  mutation rootUserUpdate($id: ID!, $user: UserInput!) {
    rootUserUpdate(_id: $id, user: $user) {
      _id
    }
  }
`);

const USER_CREATE = gql(`
  mutation rootUserCreate($user: UserInput!) {
    rootUserCreate(user: $user) {
      _id
    }
  }
`);

const USER_DELETE = gql(`
  mutation rootUserDelete($id: ID!) {
    rootUserDelete(_id: $id) {
      success
      message
    }
  }
`);

export { USERS, USER_UPDATE, USER_CREATE, USER_DELETE };
