import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useApolloNetworkStatus } from "libs/apollo/createClient";
import { Loading } from "ui/Loading/Loading";

const GlobalLoading = () => {
  const status = useApolloNetworkStatus();

  if (status.numPendingQueries == 0) {
    return (
      <Box
        position={"fixed"}
        display="flex"
        flex={1}
        top={0}
        left={0}
        width={"100vw"}
        height={"100vh"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        bg={"blackAlpha.200"}
        zIndex={99999}
      >
        <Spinner
          thickness="6px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.500"
          size="xl"
        />
      </Box>
    );
  } else {
    return null;
  }
};

export { GlobalLoading };
