import { useQuery } from "@apollo/client";
import { Box, VStack } from "@chakra-ui/react";
import { PoiReport } from "components/Poi/PoiReport";
import React from "react";
import { POIS_GET_ALLAREAS_STATS } from "stores/queries/pois";

const AdminDashboard = () => {
  const { data, loading } = useQuery(POIS_GET_ALLAREAS_STATS);
  return (
    <Box w="full">
      <VStack alignItems="stretch" maxW="1800px" spacing={10}>
        {data?.poiGetAllAreasStatistics?.map((area) => {
          return (
            <PoiReport
              key={area?.area}
              statistics={area?.statistics}
              emailStatistics={area?.emailStatistics}
              loading={loading}
              area={area?.area}
            />
          );
        })}
      </VStack>
    </Box>
  );
};

export { AdminDashboard };
