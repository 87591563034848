import { logger } from "react-native-logs";

import { deviceInfo } from "./deviceInfo";
import env from "../env.json";

const __DEV__ = env.debug;

type Env = "development" | "production" | "test";

// INIZIALIZZO VARIABILI D'AMBIENTE
interface EnvVars {
  mode: "development" | "production" | "test";
  deviceInfo: {
    system: string;
    app: {
      version: string;
      name: string;
    };
  };
  backend: {
    uri: string;
    api: string;
  };
  auth: {
    uri: string;
  };
  db: {
    cacheKey: string;
    authKey: string;
    appKey: string;
  };
}

const ENV: EnvVars = {
  deviceInfo,
  mode: env.mode as Env,
  backend: {
    uri: env.backend.uri,
    api: env.backend.api,
  },
  auth: {
    uri: env.auth.uri,
  },
  db: {
    cacheKey: __DEV__ ? "DEV_TB_CACHE" : "TB_CACHE",
    authKey: __DEV__ ? "DEV_TB_AUTH" : "TB_AUTH",
    appKey: __DEV__ ? "DEV_TB_APP" : "TB_APP",
  },
};

// INIZIALIZZO LOGGER
const loggerConfig = {
  severity: __DEV__ ? "debug" : "info",
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  transportOptions: {
    colors: {
      info: "green",
      warn: "blue",
      error: "red",
    },
  },
  async: true,
  dateFormat: "time",
  printLevel: true,
  printDate: true,
  enabled: true,
  printFileLine: true,
};

const DEFAULT_ERROR = "Errore sconosciuto, contatta un amministratore...";

const LOG =
  logger.createLogger<keyof (typeof loggerConfig)["levels"]>(loggerConfig);

// LOG AVVIO APP
LOG.info(
  `${ENV.deviceInfo.app.name} | v. ${ENV.deviceInfo.app.version} | ${ENV.deviceInfo.system} | ENV: ${ENV.mode}`
);

export { ENV, LOG, __DEV__, DEFAULT_ERROR };
