import { gql } from "@apollo/client";
import { graphql } from "libs/apollo/__generated__";

const TRANSPORT_FRAGMENT = graphql(`
  fragment transportFragment on Transport {
    _id
    name {
      langIso
      value
    }
    statuses {
      manual {
        status
        lastUpdate
        motivation {
          langIso
          value
        }
        expiration
      }
      live {
        status
        updatedOrigin
        lastUpdate
      }
      daily {
        status
        updatedOrigin
        lastUpdate
      }
      calendar {
        status
        lastUpdate
      }
      external {
        status
        lastUpdate
      }
    }
    externalInfo {
      tgpId
      externalId
      externalSource
      updateLink
    }
    calendar {
      timeEncoding {
        times
      }
    }
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
    owner
    skiarea
    secure {
      code
      permissions {
        manual
        calendar
      }
    }
    eventsCount
    termsAndConditions {
      accepted
    }
    category
  }
`);

const TRANSPORT = graphql(`
  query adminTransport($_id: ID!) {
    adminTransport(_id: $_id) {
      ...transportFragment
    }
  }
`);

const TRANSPORTS = graphql(`
  query adminTransports {
    adminTransports {
      ...transportFragment
    }
  }
`);

const TRANSPORTS_MIN = graphql(`
  query adminTransports_min {
    adminTransports {
      _id
      name {
        langIso
        value
      }
      statuses {
        live {
          status
          updatedOrigin
          lastUpdate
        }
      }
      secure {
        code
        permissions {
          manual
          calendar
        }
      }
      eventsCount
    }
  }
`);

const TRANSPORT_CALENDAR = graphql(`
  query adminTransportGetCalendarDays($_id: ID!, $from: Date!, $to: Date!) {
    adminTransportGetCalendarDays(_id: $_id, from: $from, to: $to) {
      day
      schedules
    }
  }
`);

const TRANSPORTS_CALENDAR_ADDRANGE = graphql(`
  mutation adminTransportsCalendarAddRange(
    $_ids: [ID!]!
    $range: CalendarRangeInput
  ) {
    adminTransportsCalendarAddRange(_ids: $_ids, range: $range) {
      _id
    }
  }
`);

const TRANSPORTS_STATUS_UPDATE = graphql(`
  mutation adminTransportsUpdateStatus(
    $_ids: [ID!]!
    $status: Boolean
    $motivation: [LocalizationInput]
    $expiration: Date
  ) {
    adminTransportsUpdateStatus(
      _ids: $_ids
      status: $status
      motivation: $motivation
      expiration: $expiration
    ) {
      _id
    }
  }
`);

const TRANSPORT_RANGE = graphql(`
  query transportGetTimeRangeInBaseOfTime($_id: ID!) {
    transportGetTimeRangeInBaseOfTime(_id: $_id)
  }
`);

const TRANSPORT_CALENDAR_RESET = graphql(`
  mutation adminTransportCalendarReset($_id: ID!) {
    adminTransportCalendarReset(_id: $_id) {
      _id
    }
  }
`);

const TRANSPORTS_SECURE_MANAGE = graphql(`
  mutation adminTransportsSecureManage($_ids: [ID!]!, $secure: SecureInput!) {
    adminTransportsSecureManage(_ids: $_ids, secure: $secure) {
      _id
      secure {
        code
        permissions {
          manual
          calendar
        }
      }
    }
  }
`);

const TRANSPORTS_GET_QR_PDF = gql`
  mutation AdminTransportGetQrCodePdf($_id: ID!) {
    adminTransportGetQrCodePdf(_id: $_id)
  }
`;

const TRANSPORTS_GET_QR_EMAIL = gql`
  mutation AdminTransportsSendEmail($_ids: [ID!]!) {
    adminTransportsSendEmail(_ids: $_ids) {
      _id
      success
      errors
    }
  }
`;

const TRANSPORT_ACCEPT_TERMS = gql`
  mutation AdminTransportAcceptTermsAndConditions($id: ID!) {
    adminTransportAcceptTermsAndConditions(_id: $id) {
      _id
    }
  }
`;

export {
  TRANSPORT,
  TRANSPORTS,
  TRANSPORTS_MIN,
  TRANSPORT_CALENDAR,
  TRANSPORTS_CALENDAR_ADDRANGE,
  TRANSPORTS_STATUS_UPDATE,
  TRANSPORT_CALENDAR_RESET,
  TRANSPORT_RANGE,
  TRANSPORTS_SECURE_MANAGE,
  TRANSPORTS_GET_QR_PDF,
  TRANSPORTS_GET_QR_EMAIL,
  TRANSPORT_ACCEPT_TERMS,
};
