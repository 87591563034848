import React, { useEffect, useState } from "react";
import { useInitResource } from "./hooks/useInitResource";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { Button, Center, ChakraProvider, Heading } from "@chakra-ui/react";
import { Router } from "navigation";
import { GoogleOAuthProvider } from "@react-oauth/google";

import theme from "./theme/theme";
import { GlobalLoading } from "components/GlobalLoading/GlobalLoading";
import { ErrorHelper } from "components/ErrorHelper/ErrorHelper";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";

const App = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initResource = useInitResource();

  useEffect(() => {
    if (initResource?.apolloClient) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [initResource]);

  return (
    <GoogleOAuthProvider clientId="59082099396-vota4o6bld6gg8l0ni49jhv837rhj154.apps.googleusercontent.com">
      <ChakraProvider theme={theme}>
        {isLoading && <GlobalLoading />}
        {initResource?.apolloClient && (
          <ApolloProvider client={initResource.apolloClient}>
            <ErrorBoundary
              fallback={
                <Center h="100vh" flexDir="column">
                  <Heading>Si è verificato un errore :/</Heading>
                  <Button
                    variant="brand"
                    mt="5"
                    onClick={() => window.location.reload()}
                  >
                    Ricarica
                  </Button>
                </Center>
              }
            >
              <BrowserRouter /* basename="/" */>
                <Router />
              </BrowserRouter>
            </ErrorBoundary>
            <ErrorHelper />
          </ApolloProvider>
        )}
      </ChakraProvider>
    </GoogleOAuthProvider>
  );
};

export { App };
