import { gql } from "@apollo/client";
import { graphql } from "libs/apollo/__generated__";

const TRACK_FRAGMENT = graphql(`
  fragment trackFragment on Track {
    _id
    name {
      langIso
      value
    }
    statuses {
      manual {
        status
        lastUpdate
        motivation {
          langIso
          value
        }
        expiration
      }
      live {
        status
        updatedOrigin
        lastUpdate
      }
      daily {
        status
        updatedOrigin
        lastUpdate
      }
      calendar {
        status
        lastUpdate
      }
      external {
        status
        lastUpdate
      }
    }
    externalInfo {
      tgpId
      externalId
      externalSource
      updateLink
    }
    calendar {
      timeEncoding {
        times
      }
    }
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
    owner
    skiarea
    secure {
      code
      permissions {
        manual
        calendar
      }
    }
    eventsCount
    termsAndConditions {
      accepted
    }
    category
  }
`);

const TRACK = graphql(`
  query adminTrack($_id: ID!) {
    adminTrack(_id: $_id) {
      ...trackFragment
    }
  }
`);

const TRACKS = graphql(`
  query adminTracks {
    adminTracks {
      ...trackFragment
    }
  }
`);

const TRACKS_MIN = graphql(`
  query adminTracks_min {
    adminTracks {
      _id
      name {
        langIso
        value
      }
      statuses {
        live {
          status
          updatedOrigin
          lastUpdate
        }
      }
      secure {
        code
        permissions {
          manual
          calendar
        }
      }
      eventsCount
    }
  }
`);

const TRACK_CALENDAR = graphql(`
  query adminTrackGetCalendarDays($_id: ID!, $from: Date!, $to: Date!) {
    adminTrackGetCalendarDays(_id: $_id, from: $from, to: $to) {
      day
      schedules
    }
  }
`);

const TRACKS_CALENDAR_ADDRANGE = graphql(`
  mutation adminTracksCalendarAddRange(
    $_ids: [ID!]!
    $range: CalendarRangeInput
  ) {
    adminTracksCalendarAddRange(_ids: $_ids, range: $range) {
      _id
    }
  }
`);

const TRACKS_STATUS_UPDATE = graphql(`
  mutation adminTracksUpdateStatus(
    $_ids: [ID!]!
    $status: Boolean
    $motivation: [LocalizationInput]
    $expiration: Date
  ) {
    adminTracksUpdateStatus(
      _ids: $_ids
      status: $status
      motivation: $motivation
      expiration: $expiration
    ) {
      _id
    }
  }
`);

const TRACK_RANGE = graphql(`
  query trackGetTimeRangeInBaseOfTime($_id: ID!) {
    trackGetTimeRangeInBaseOfTime(_id: $_id)
  }
`);

const TRACK_CALENDAR_RESET = graphql(`
  mutation adminTrackCalendarReset($_id: ID!) {
    adminTrackCalendarReset(_id: $_id) {
      _id
    }
  }
`);

const TRACKS_SECURE_MANAGE = graphql(`
  mutation adminTracksSecureManage($_ids: [ID!]!, $secure: SecureInput!) {
    adminTracksSecureManage(_ids: $_ids, secure: $secure) {
      _id
      secure {
        code
        permissions {
          manual
          calendar
        }
      }
    }
  }
`);

const TRACKS_GET_QR_PDF = gql`
  mutation AdminTrackGetQrCodePdf($_id: ID!) {
    adminTrackGetQrCodePdf(_id: $_id)
  }
`;

const TRACKS_GET_QR_EMAIL = gql`
  mutation AdminTracksSendEmail($_ids: [ID!]!) {
    adminTracksSendEmail(_ids: $_ids) {
      _id
      success
      errors
    }
  }
`;

const TRACK_ACCEPT_TERMS = gql`
  mutation AdminTrackAcceptTermsAndConditions($id: ID!) {
    adminTrackAcceptTermsAndConditions(_id: $id) {
      _id
    }
  }
`;

export {
  TRACK,
  TRACKS,
  TRACKS_MIN,
  TRACK_CALENDAR,
  TRACKS_CALENDAR_ADDRANGE,
  TRACKS_STATUS_UPDATE,
  TRACK_CALENDAR_RESET,
  TRACK_RANGE,
  TRACKS_SECURE_MANAGE,
  TRACKS_GET_QR_PDF,
  TRACKS_GET_QR_EMAIL,
  TRACK_ACCEPT_TERMS,
};
