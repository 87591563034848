import { useMutation, useQuery } from "@apollo/client";
import { Box, Divider, Flex, Skeleton, Text } from "@chakra-ui/react";
import { CalendarStatus } from "components/Status/CalendarStatus";
import { LiveStatus } from "components/Status/LiveStatus";
import { ManualStatus } from "components/Status/ManualStatus";
import { formatDate } from "libs/helpers";
import { secure } from "stores/db/appstate";
import { CalendarDay, Resource } from "stores/queries/area";

import {
  POI,
  POIS_STATUS_UPDATE,
  POIS_STATUS_UPDATE_OPERATOR,
  POI_CALENDAR,
} from "stores/queries/pois";

type PoiStatusProps = {
  _id: string;
  operator?: boolean;
};

const PoiStatus = (props: PoiStatusProps) => {
  const { loading: cLoading, data: cData } = useQuery<{
    adminPoiGetCalendarDays: CalendarDay[];
  }>(POI_CALENDAR, {
    variables: {
      from: formatDate(new Date()),
      to: formatDate(new Date()),
      _id: props._id,
    },
    context: secure() ? { headers: { secureCode: `${secure()}` } } : undefined,
    fetchPolicy: "network-only",
  });

  const {
    loading: rLoading,
    data: rData,
    refetch,
  } = useQuery(POI, {
    variables: { _id: props._id },
    fetchPolicy: "no-cache",
    context: secure() ? { headers: { secureCode: `${secure()}` } } : undefined,
    pollInterval: 12000,
  });

  const [updateStatus, { loading: usLoading }] = useMutation<{
    adminPoisUpdateStatus: Resource[];
  }>(props.operator ? POIS_STATUS_UPDATE_OPERATOR : POIS_STATUS_UPDATE, {
    context: secure() ? { headers: { secureCode: `${secure()}` } } : undefined,
    refetchQueries: [POI],
    awaitRefetchQueries: true,
  });

  return (
    <Box flex={1} position={"relative"}>
      <Skeleton isLoaded={!rLoading}>
        <LiveStatus
          _id={props._id}
          manual={rData?.adminPoi?.statuses?.manual}
          live={rData?.adminPoi?.statuses?.live}
        />
      </Skeleton>

      {rData?.adminPoi?.statuses?.live?.updatedOrigin !== "EXTERNAL" ? (
        <Box tour-id="poi-status">
          <Skeleton isLoaded={!cLoading && !rLoading}>
            <CalendarStatus
              today={cData?.adminPoiGetCalendarDays[0]}
              status={rData?.adminPoi?.statuses?.calendar}
              manualStatus={rData?.adminPoi?.statuses?.manual?.status}
              calendarMeta={rData?.adminPoi?.calendarMetadata}
              _id={props._id}
            />
          </Skeleton>
          <Divider />
          <Skeleton isLoaded={!rLoading}>
            <Flex direction={["column", "row"]} width={"100%"}>
              <ManualStatus
                updateStatus={updateStatus}
                _id={props._id}
                manual={rData?.adminPoi?.statuses?.manual}
                live={rData?.adminPoi?.statuses?.live}
                onUpdated={() => {
                  refetch();
                }}
              />
            </Flex>
          </Skeleton>
        </Box>
      ) : (
        <Skeleton isLoaded={!rLoading}>
          <Flex direction={["column", "row"]} width={"100%"}>
            {!secure() && <Box width={"230px"}></Box>}
            <Text flex={1} textAlign={"center"}>
              Per modificare lo stato devi agire sulla fonte esterna
            </Text>
          </Flex>
        </Skeleton>
      )}
    </Box>
  );
};

export { PoiStatus };
