import {
  Button,
  Divider,
  Hide,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";

import { TimeIcon } from "@chakra-ui/icons";
import Countdown from "react-countdown";

import Card from "ui/Card";
import { useState } from "react";
import { secure } from "stores/db/appstate";
import { ApolloMutation, CalendarDay, Resource } from "stores/queries/area";
import { POI } from "stores/queries/pois";
import { AdminPoisUpdateStatusMutationVariables } from "libs/apollo/__generated__/graphql";

const CountDownRender = ({ hours, minutes, seconds, completed }: any) => {
  if (completed) {
    // ?
  } else {
    return (
      <Text>
        ( - {hours}h {minutes}m {seconds}s )
      </Text>
    );
  }
};

type ManualStatusProps = {
  updateStatus: ApolloMutation;
  manual: Resource["statuses"]["manual"];
  live: Resource["statuses"]["live"];
  _id: string;
  onUpdated?: () => void;
};

const ManualStatus = (props: ManualStatusProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [motivation, setMotivation] = useState<1 | 2 | 3 | 4>(1);
  const [expiration, setExpiration] = useState(0);

  const status = props.live;

  const isManual =
    status?.updatedOrigin === "MANUAL" &&
    typeof props?.manual?.status === "boolean";

  const motivations = {
    it: [
      "Per evento",
      "Per meteo avverso",
      "Per manutenzione",
      "Per problemi tecnici",
    ],
    en: [
      "Due to event",
      "Due to adverse weather",
      "Due to maintenance",
      "Due to technical issues",
    ],
    de: [
      "Wegen Veranstaltung",
      "Wegen widrigen Wetters",
      "Wegen Wartung",
      "Wegen technischer Probleme",
    ],
  } as const;

  const expirationTime = props?.manual?.expiration
    ? new Date(new Date(props.manual.expiration)).toLocaleTimeString()
    : null;

  return (
    <VStack w="100%" my={5}>
      <Stack
        direction={{ base: "column", lg: "row", xl: "row" }}
        w="100%"
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        {!secure() && (
          <Hide below="md">
            <VStack>
              <Text
                textAlign={"right"}
                width={"230px"}
                fontSize={"2xl"}
                fontWeight={"bold"}
                mr={2}
              >
                Modifica stato in tempo reale:
              </Text>
            </VStack>
          </Hide>
        )}
        <VStack
          w={"100%"}
          flex={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {isManual && props.manual?.expiration ? (
            <Card
              p={1}
              flex={1}
              borderColor={"pink.500"}
              borderWidth={1}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <HStack>
                <TimeIcon fontSize={"2xl"} m={3} />
                <VStack justifyContent={"flex-start"} alignItems={"flex-start"}>
                  <HStack>
                    <Hide below="md">
                      <Text fontWeight={"bold"}>
                        {expirationTime === "00:00:00"
                          ? "Chiuso manualmente fino a fine giornata"
                          : "Chiuso manualmente fino alle " + expirationTime}
                      </Text>
                    </Hide>

                    <Countdown
                      date={new Date(props.manual.expiration)}
                      renderer={CountDownRender}
                    />
                  </HStack>
                  <Hide below="md">
                    <Text>
                      Motivazione:{" "}
                      <b>
                        {props.manual?.motivation[0]?.value ||
                          "Non specificata"}
                      </b>
                    </Text>
                  </Hide>
                </VStack>
              </HStack>
            </Card>
          ) : null}
          {isManual ? (
            <Card p={1} flex={1} bgColor={"white"}>
              <Button
                onClick={() => {
                  props
                    .updateStatus({
                      variables: {
                        _ids: [props._id],
                        status: null,
                      },
                      /*                     refetchQueries: [POI],
                    awaitRefetchQueries: true, */
                    })
                    .then(() => {
                      props.onUpdated && props.onUpdated();
                    });
                }}
                style={{ minHeight: "44px" }}
                colorScheme={"yellow"}
                variant="solid"
              >
                Annulla ora
              </Button>
              <Text flex={1} textAlign={"center"}>
                Annulla l'attuale stato manuale per reimpostare lo stato da
                calendario
              </Text>
            </Card>
          ) : (
            <Card p={1} flex={1} bgColor={"white"}>
              <Button
                onClick={() => {
                  setExpiration(0);
                  onOpen();
                }}
                style={{ minHeight: "44px" }}
                colorScheme={"pink"}
                className="manual-status-btn"
              >
                Chiudi ora
              </Button>
              <Text flex={1} textAlign={"center"}>
                In caso di imprevisti puoi impostare una chiusura manuale per un
                tempo limitato{" "}
              </Text>
            </Card>
          )}
        </VStack>

        {/*         {Btns(status?.status)} */}
      </Stack>
      {/* <Stack
        direction={{ base: "column", lg: "row", xl: "row" }}
        w="100%"
        alignItems="center"
        justifyContent={"center"}
      >
        <Hide below="md">
          <VStack alignItems={"flex-end"} mr={2}>
            <Text textAlign={"right"} width={"230px"} fontSize={"xl"}>
              Fonte
            </Text>
          </VStack>
        </Hide>

        <Card p={1} flex={1} bgColor={"white"} borderWidth={1}>
          <Text
            textAlign={"center"}
            color={"gray.700"}
            fontSize={"xl"}
            w="100%"
            textTransform={"uppercase"}
          >
            {txt}
          </Text>
        </Card>
      </Stack> */}
      {/*       {status?.motivation[0]?.value ? (
        <Stack direction={{ base: "column", lg: "row", xl: "row" }}>
          <Box width={"230px"} />
          <Text flex={1} textAlign={"center"}>
            <b>MSG:</b> {status.motivation[0].value}
          </Text>
          <Box width={"120px"} />
        </Stack>
      ) : null} */}
      {/*  {!isCalendar && !isLocked && !managed ? (
        <Stack
          alignItems={"center"}
          direction={{ base: "column", lg: "row", xl: "row" }}
        >
          <Box width={"230px"} />
          {OpenBtn} {CloseBtn}
        </Stack>
      ) : null} */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="manual-status-modal">
          <ModalCloseButton tour-id="status-modal-close" />
          <ModalBody>
            <Text fontSize={"2xl"} fontWeight={"bold"} mt={1}>
              Durata della chiusura
            </Text>
            <Text fontSize={"sm"}>
              Seleziona per quanto tempo prevedi di chiudere
            </Text>
            <Select
              value={expiration}
              onChange={(event) => {
                setExpiration(parseInt(event.target.value) as any);
              }}
              my={3}
              defaultValue={0}
              colorScheme={"blackAlpha"}
            >
              <option value="0" style={{ color: "#010101" }}>
                Tutto il giorno
              </option>
              <option value="1" style={{ color: "#010101" }}>
                1 Ora
              </option>
              <option value="2" style={{ color: "#010101" }}>
                2 Ore
              </option>
              <option value="4" style={{ color: "#010101" }}>
                4 Ore
              </option>
            </Select>
            <Divider />
            <Text fontSize={"2xl"} fontWeight={"bold"} mt={4}>
              Motivazione della chiusura
            </Text>
            <Text fontSize={"sm"}>
              Seleziona la causa della chiusura imprevista
            </Text>
            <Select
              onChange={(event) => {
                setMotivation(parseInt(event.target.value) as any);
              }}
              my={3}
              defaultValue={0}
            >
              {motivations.it.map((m, i) => (
                <option key={i} value={i + 1} style={{ color: "#010101" }}>
                  {m}
                </option>
              ))}
            </Select>
            <Divider />
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose} variant="ghost">
              Annulla
            </Button>
            <Button
              colorScheme="pink"
              onClick={() => {
                let d = new Date();
                if (!expiration || expiration === 0) {
                  d.setHours(24, 0, 0, 0);
                } else {
                  d.setHours(d.getHours() + expiration);
                }

                props
                  .updateStatus({
                    variables: {
                      expiration: d,
                      _ids: [props._id],
                      status: false,
                      motivation: motivation
                        ? Object.entries(motivations).map(([k, v]) => ({
                            langIso: k,
                            value:
                              motivations[k as keyof typeof motivations][
                                motivation - 1
                              ],
                          }))
                        : undefined,
                    } satisfies AdminPoisUpdateStatusMutationVariables,
                    /*   refetchQueries: [POI],
                  awaitRefetchQueries: true, */
                  })
                  .then(() => {
                    props.onUpdated && props.onUpdated();
                  });
                onClose();
              }}
            >
              Conferma e chiudi
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export { ManualStatus };
