import { ENV, LOG } from "../../config";
import { cacheStorage, clear, dbStorage } from "./wrapper";

const log = LOG.extend("STORAGES");

const D = "__";

const appKey = (key: string) => ENV.db.appKey + D + key;

const DB = {
  store: dbStorage,
  getObject: async (key: string) => {
    let result = null;
    try {
      let strResult = await dbStorage.getItem(appKey(key));
      result = strResult ? JSON.parse(strResult) : null;
    } catch (error: any) {
      log.error("appStorage " + appKey(key) + " | " + error?.message);
    }
    return result;
  },
  getString: async (key: string) => {
    let result = null;
    try {
      result = await dbStorage.getItem(appKey(key));
    } catch (error: any) {
      log.error("appStorage " + appKey(key) + " | " + error?.message);
    }
    return result;
  },
  setObject: async (key: string, item: object) => {
    let strItem = JSON.stringify(item);
    let result = await dbStorage.setItem(appKey(key), strItem);
    return result;
  },
  setString: async (key: string, item: string) => {
    let result = await dbStorage.setItem(appKey(key), item);
    return result;
  },
  remove: async (key: string) => {
    let result = await dbStorage.removeItem(appKey(key));
    return result;
  },
};

const clearCache = () => {
  clear();
};

export { cacheStorage, dbStorage, DB, clearCache };
