import { ChevronLeftIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons";
import {
  Text,
  useCheckboxGroup,
  Flex,
  IconButton,
  Divider,
  theme,
  Checkbox,
  Button,
  Box,
} from "@chakra-ui/react";
import { endOfWeek, startOfWeek, subDays, nextMonday } from "date-fns";
import { isBeforeToday } from "libs/helpers";
import { Resource } from "stores/queries/area";
import { WeekDay } from "./OpeningsView";

type CalendarPageProps = {
  selectedDays: any;
  selectedWeek: Date;
  setSelectedWeek: (w: Date) => void;
  week: WeekDay[];
  setPage: any;
  setSelDay: (a: any) => void;
  setDayCopy: (a: any) => void;
  calendarMeta: Resource["calendarMetadata"];
};

const CalendarPage = ({
  selectedDays,
  setPage,
  week,
  selectedWeek,
  setSelDay,
  setSelectedWeek,
  setDayCopy,
  calendarMeta,
}: CalendarPageProps) => {
  const { value: days, getCheckboxProps } = useCheckboxGroup({
    onChange: (selectedValues: (string | number)[]) => {
      selectedDays.current = selectedValues;
    },
  });

  /* onChange((values: string[]) => {
    console.log(values);
    //setDays(values)
  }); */

  return (
    <>
      <Flex
        maxHeight={"100%"}
        overflow={"hidden"}
        flex={1}
        maxWidth={"1200px"}
        width={"100%"}
        borderTopRadius={30}
        backgroundColor={"#fff"}
        flexDirection={"column"}
      >
        <Flex p={5} py={4} width={"100%"} flexDirection={"row"}>
          <IconButton
            onClick={() => {
              let sel = subDays(
                startOfWeek(selectedWeek, { weekStartsOn: 1 }),
                1
              );
              if (isBeforeToday(sel)) {
                return;
              }
              setSelectedWeek(
                subDays(startOfWeek(selectedWeek, { weekStartsOn: 1 }), 1)
              );
            }}
            isRound
            size={"sm"}
            variant="solid"
            aria-label="Call Sage"
            fontSize="20px"
            icon={<ChevronLeftIcon />}
          />
          <Flex flex={1} flexDirection={"column"}>
            <Text
              textTransform={"capitalize"}
              fontSize={16}
              colorScheme={"gray"}
              lineHeight={1.2}
              textAlign={"center"}
            >
              Settimana
            </Text>
            <Text
              textAlign={"center"}
              fontSize={16}
              fontWeight={"bold"}
              lineHeight={1.2}
              textTransform={"capitalize"}
            >
              {startOfWeek(selectedWeek, {
                weekStartsOn: 1,
              }).toLocaleDateString("it", {
                weekday: "short",
              })}{" "}
              {startOfWeek(selectedWeek, { weekStartsOn: 1 }).getDate()}{" "}
              {startOfWeek(selectedWeek, { weekStartsOn: 1 }).toLocaleString(
                "it",
                {
                  month: "short",
                }
              )}{" "}
              -{" "}
              {endOfWeek(selectedWeek, {
                weekStartsOn: 1,
              }).toLocaleDateString("it", {
                weekday: "short",
              })}{" "}
              {endOfWeek(selectedWeek, { weekStartsOn: 1 }).getDate()}{" "}
              {endOfWeek(selectedWeek, { weekStartsOn: 1 }).toLocaleString(
                "it",
                {
                  month: "short",
                }
              )}
            </Text>
          </Flex>
          <IconButton
            onClick={() => {
              // console.log(
              //   "setting",
              //   addDays(endOfWeek(selectedWeek), 1).toLocaleDateString()
              // );
              // setSelectedWeek(addDays(endOfWeek(selectedWeek), 1));
              setSelectedWeek(nextMonday(selectedWeek));
            }}
            isRound
            size={"sm"}
            variant="solid"
            aria-label="Call Sage"
            fontSize="20px"
            icon={<ChevronRightIcon />}
          />
        </Flex>
        {(() => {
          if (!calendarMeta?.lastDay) return;
          const lastDay = new Date(calendarMeta?.lastDay);
          return (
            <Flex
              py={2}
              width={"100%"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text fontSize={15} lineHeight={1.2} textAlign={"center"}>
                Ultima data a calendario
              </Text>
              <Text
                fontSize={15}
                fontWeight={"bold"}
                lineHeight={1.2}
                textTransform={"capitalize"}
              >
                {lastDay.toLocaleDateString("it", {
                  weekday: "short",
                })}{" "}
                {lastDay.getDate()}{" "}
                {lastDay.toLocaleString("it", {
                  month: "long",
                })}
                {", "}
                {lastDay.getFullYear()}
              </Text>
            </Flex>
          );
        })()}

        <Divider borderColor={"#464646"} />

        <Box overflowY={"auto"} height={"100%"} pt={4} pb={14}>
          {week.length > 0 &&
            week.map((day, index) => (
              <Flex
                key={"day_" + index}
                minHeight={"44px"}
                //height={"60px"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                maxWidth={"1200px"}
                width={"100%"}
                py={2}
                px={4}
                //py={8}
                bgColor={
                  new Date().toLocaleDateString() ===
                  day.date.toLocaleDateString()
                    ? theme.colors.blue[50]
                    : undefined
                }
                opacity={isBeforeToday(day.date) ? 0.8 : 1}
              >
                <Checkbox
                  {...getCheckboxProps({
                    value: index,
                  })}
                  defaultChecked={false}
                  colorScheme="brandScheme"
                  outline="1px solid #dcdcdc"
                  tour-id="weekday-checkbox"
                  borderRadius={3}
                  size={"lg"}
                />

                <Flex flex={1} flexDirection={"column"} ml={4}>
                  <Flex flexDirection={"row"} alignItems="center">
                    <Box
                      borderWidth={1}
                      borderRadius={5}
                      width={"20px"}
                      height={"20px"}
                      mb={"0px"}
                      mr={1}
                      bgColor={
                        day.status
                          ? "green.100"
                          : typeof day.status === "boolean"
                          ? "pink.100"
                          : "gray.200"
                      }
                    >
                      <Text
                        fontSize={"sm"}
                        fontWeight={"bold"}
                        width={"100%"}
                        textAlign={"center"}
                        textTransform={"capitalize"}
                        //lineHeight={1.3}
                      >
                        {day.dayNum}
                      </Text>
                    </Box>
                    <Text
                      textTransform={"capitalize"}
                      fontSize={16}
                      lineHeight={1.2}
                      fontWeight={"bold"}
                      onClick={() => {
                        setSelDay(day);
                        setPage(2);
                      }}
                      cursor={"pointer"}
                    >
                      {day.dayName}:
                    </Text>
                    <Text
                      ml={2}
                      textTransform={"capitalize"}
                      fontSize={16}
                      lineHeight={1.3}
                      color={
                        day.status
                          ? theme.colors.green[500]
                          : typeof day.status === "boolean"
                          ? theme.colors.pink[500]
                          : theme.colors.gray[500]
                      }
                    >
                      {day.status
                        ? "Aperto"
                        : typeof day.status === "boolean"
                        ? "Chiuso"
                        : "Non impostato"}
                    </Text>
                  </Flex>
                  {day.schedules &&
                  (day.schedules[0] === "00:00-24:00" ||
                    day.schedules.length === 0) ? (
                    <Text ml={6} fontSize={14} lineHeight={1.2}>
                      Tutto il giorno
                    </Text>
                  ) : (
                    <Flex ml={6} flexDirection={"row"}>
                      {day.schedules?.length > 0 &&
                        day.schedules.map((schedule: any, index: number) => (
                          <Text
                            key={"hh_" + index}
                            mr={2}
                            fontSize={14}
                            lineHeight={1.2}
                          >
                            {schedule}
                          </Text>
                        ))}
                    </Flex>
                  )}
                </Flex>
                {!isBeforeToday(day.date) ? (
                  <IconButton
                    className="edit-weekday-btn"
                    onClick={() => {
                      setSelDay(day);
                      setPage(2);
                    }}
                    isRound
                    size={"sm"}
                    variant="ghost"
                    aria-label="Call Sage"
                    fontSize="24px"
                    icon={<EditIcon color={theme.colors.gray[500]} />}
                  />
                ) : null}
              </Flex>
            ))}
        </Box>
      </Flex>

      {days?.length > 0 && (
        <Flex
          maxWidth={"1200px"}
          width={"100%"}
          backgroundColor={"#fff"}
          flexDirection={"row"}
          p={4}
          justifyContent={"center"}
          alignItems={"center"}
          tour-id="copy-section"
        >
          <Button
            mx={2}
            onClick={() => {
              setPage(3);
            }}
            variant={"outline"}
            colorScheme={"brand"}
            tour-id="copy-period-btn"
          >
            Copia su periodo
          </Button>
          {days?.length === 1 && (
            <Button
              mx={2}
              onClick={() => {
                let dayIndex = days[0];
                setDayCopy(dayIndex);
                setPage(4);
              }}
              variant={"outline"}
              colorScheme={"brand"}
              tour-id="copy-day-btn"
            >
              Copia in settimana
            </Button>
          )}
        </Flex>
      )}
    </>
  );
};

export { CalendarPage };
