import { ChevronRightIcon, DeleteIcon, SmallAddIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Flex,
  theme,
  Button,
  Divider,
  HStack,
  VStack,
  Circle,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { formatDate } from "@fullcalendar/core";
import React from "react";
import { useState, useEffect } from "react";

type DayPageProps = {
  selDay: any;
  setPage: any;
  addRange: any;
  _id: string;
  getWeek: () => void;
};
const DayPage = ({ selDay, setPage, addRange, _id, getWeek }: DayPageProps) => {
  const [isOpen, setIsOpen] = useState(undefined);
  const [timesRange, setTimesRange] = useState([]);

  useEffect(() => {
    setIsOpen(
      selDay?.status
        ? true
        : typeof selDay?.status === "boolean"
        ? false
        : undefined
    );
    if (
      selDay?.schedules?.length === 1 &&
      selDay?.schedules[0] === "00:00-24:00"
    ) {
      setTimesRange([]);
    } else {
      let times = [];
      if (selDay?.schedules?.length) {
        for (let i = 0; i < selDay.schedules.length; i++) {
          let timesArr = selDay.schedules[i].split("-");
          times.push(timesArr);
        }
      }
      setTimesRange(times);
    }
  }, []);

  useEffect(() => {
    if (timesRange.length > 0) {
      setIsOpen(true);
    }
  }, [timesRange]);

  if (!selDay) {
    setPage(1);
    return null;
  }

  return (
    <>
      <Flex
        maxHeight={"100%"}
        overflow={"hidden"}
        flex={1}
        maxWidth={"1200px"}
        width={"100%"}
        borderTopRadius={30}
        backgroundColor={"#fff"}
        flexDirection={"column"}
        p={6}
        alignItems={"flex-start"}
      >
        <Flex
          width={"100%"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Flex my={1} flexDirection={"row"} alignItems="center">
            <Box
              borderWidth={1}
              borderRadius={5}
              px={1}
              mb={"0px"}
              mr={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              bgColor={
                isOpen
                  ? theme.colors.green[200]
                  : typeof isOpen === "boolean"
                  ? theme.colors.pink[200]
                  : theme.colors.gray[200]
              }
            >
              <Text
                fontSize={"lg"}
                width={"100%"}
                textAlign={"center"}
                textTransform={"capitalize"}
              >
                {selDay.dayNum} {selDay.monthName}
              </Text>
            </Box>

            <Text
              textTransform={"capitalize"}
              fontSize={20}
              fontWeight={"bold"}
              color={theme.colors.gray[600]}
            >
              {selDay.dayName}
            </Text>
          </Flex>
          {isOpen && (
            <Button
              tour-id="daypage-close"
              onClick={() => {
                setTimesRange([]);
                setIsOpen(false);
              }}
              size={"sm"}
              colorScheme={"red"}
            >
              Chiudi
            </Button>
          )}
          {/* <Switch
            isChecked={isOpen}
            onChange={(e) => {
              setIsOpen(e.target.checked);
            }}
            size="lg"
            colorScheme="green"
          /> */}
        </Flex>
        <Divider mt={5} />
        <Box w={"100%"} justifyContent="start" mt={5} tour-id="daypage-hours">
          {timesRange?.map((time: string, index: number) => {
            return (
              <Flex key={"timerangeweek_" + index} mb="5px">
                <HStack justifyContent="start" alignItems="start">
                  <VStack justifyContent="start" gap={0}>
                    <Box w="2px" bg="gray.200" h={"2px"} />
                    <Circle size={"5"} bg={"green.200"} />

                    <Box w="2px" bg="gray.200" h={"15px"} />
                  </VStack>
                  <VStack>
                    <HStack mb={2}>
                      <ChevronRightIcon />
                      <Box>
                        <Input
                          //style={{ backgroundColor: "#ddd" }}
                          value={timesRange[index][0]}
                          onChange={(ev) => {
                            console.log(ev.target.value);
                            let times = [...timesRange];
                            times[index][0] = ev.target.value;
                            setTimesRange(times);
                          }}
                          type="time"
                          /*  min={
                          index && timesRange[index - 1][0]
                            ? timesRange[index - 1][0]
                            : undefined
                        } */
                          //max="18:00"
                        ></Input>
                      </Box>
                      <Text fontSize="xs" as="b">
                        -
                      </Text>
                      <Box>
                        <Input
                          //style={{ backgroundColor: "#ddd" }}
                          value={timesRange[index][1]}
                          onChange={(ev) => {
                            let times = [...timesRange];
                            times[index][1] = ev.target.value;
                            setTimesRange(times);
                          }}
                          type="time"
                          //min={timesRange[index][0]}
                          //max={timesRange[index][0]}
                        ></Input>
                      </Box>
                      <IconButton
                        onClick={() => {
                          let times = [...timesRange];
                          times.splice(index, 1);
                          setTimesRange(times);
                        }}
                        isRound
                        size={"xs"}
                        variant="outline"
                        colorScheme="brand"
                        aria-label="Call Sage"
                        fontSize="14px"
                        icon={<DeleteIcon />}
                      />
                    </HStack>
                  </VStack>
                </HStack>
              </Flex>
            );
          })}
          <Flex flexDirection={"column"}>
            <HStack justifyContent="start" alignItems="start">
              {!isOpen ? (
                <Flex w={"100%"} justifyContent="center" alignItems="center">
                  <Button
                    ml={1}
                    px={4}
                    colorScheme="green"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    variant="solid"
                    tour-id="daypage-open"
                  >
                    Apri ed imposta gli orari
                  </Button>
                </Flex>
              ) : (
                <Button
                  leftIcon={
                    <Circle size={"5"} bg={"brand.500"}>
                      <SmallAddIcon color={"#fff"} />
                    </Circle>
                  }
                  ml={1}
                  colorScheme="brand"
                  onClick={() => {
                    let times = [...timesRange];
                    let start = timesRange?.length
                      ? timesRange[timesRange.length - 1][1]
                      : "08:00";

                    times.push([start, "23:59"]);
                    setTimesRange(times);
                  }}
                  variant="link"
                  tour-id="daypage-addtime"
                >
                  Aggiungi fascia oraria
                </Button>
              )}
            </HStack>
          </Flex>
          {timesRange.length < 1 && isOpen ? (
            <Text color={"brand"} p={1} py={4}>
              Non hai impostato alcuna fascia oraria, salvando risulterà aperto
              per tutto il giorno
            </Text>
          ) : null}
        </Box>
      </Flex>
      <HStack
        width={"100%"}
        maxWidth={"1200px"}
        p={3}
        justifyContent="space-around"
        alignItems="space-around"
        bgColor={theme.colors.gray[100]}
        tour-id="daypage-footer"
      >
        <Button
          size={"lg"}
          ml={1}
          colorScheme="gray"
          onClick={() => {
            setPage(1);
          }}
          variant="outline"
          tour-id="daypage-cancel"
        >
          Annulla
        </Button>
        <Button
          size={"lg"}
          ml={1}
          colorScheme="brand"
          onClick={() => {
            let range = {
              from: formatDate(selDay.date),
              to: formatDate(selDay.date),
              week: {},
            };

            if (typeof isOpen === "boolean") {
              let today = selDay?.date
                ?.toLocaleDateString("en", {
                  weekday: "long",
                })
                .toLowerCase();

              if (isOpen) {
                let times = [];

                if (timesRange?.length) {
                  for (let i = 0; i < timesRange.length; i++) {
                    let time = timesRange[i];
                    times.push(`${time[0]}-${time[1]}`);
                  }
                } else {
                  times.push(`00:00-24:00`);
                }
                (range.week as any)[today] = times;
              } else {
                (range.week as any)[today] = [];
              }
            }

            console.log(range);

            addRange({
              variables: { _ids: [_id], range },
            }).then(() => {
              getWeek();
            });

            setPage(1);
          }}
          variant="solid"
        >
          Salva
        </Button>
      </HStack>
    </>
  );
};

export { DayPage };
