import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Text,
  useDisclosure,
  Flex,
  Divider,
  HStack,
  VStack,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  theme,
} from "@chakra-ui/react";
import { formatDate } from "@fullcalendar/core";
import { endOfMonth } from "date-fns";
import { useState } from "react";
import Calendar from "react-calendar";

type CopyPageProps = {
  _id: string;
  addRange: any;
  selectedDays: any;
  week: any;
  getWeek: () => void;
  setPage: any;
};

const CopyPage = ({
  _id,
  addRange,
  selectedDays,
  week,
  getWeek,
  setPage,
}: CopyPageProps) => {
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: endOfMonth(new Date()),
  });

  const {
    isOpen: isFromOpen,
    onOpen: fromOpen,
    onClose: fromClose,
  } = useDisclosure();
  const {
    isOpen: isToOpen,
    onOpen: toOpen,
    onClose: toClose,
  } = useDisclosure();

  return (
    <>
      <Flex
        maxHeight={"100%"}
        overflow={"hidden"}
        flex={1}
        maxWidth={"1200px"}
        width={"100%"}
        borderTopRadius={30}
        backgroundColor={"#fff"}
        flexDirection={"column"}
        p={8}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={24}>
          Copia giorni selezionati
        </Text>

        <Divider py={3} />

        <Text my={4} mb={2} fontWeight={"bold"} fontSize={18}>
          Periodo
        </Text>
        <HStack>
          <VStack alignItems={"center"}>
            <Popover isOpen={isFromOpen} onOpen={fromOpen} onClose={fromClose}>
              <PopoverTrigger>
                <Button
                  size={"sm"}
                  variant={"outline"}
                  textTransform={"capitalize"}
                  tour-id="copypage-from-trigger"
                >
                  {dateRange?.start?.toLocaleDateString("it", {
                    day: "numeric",
                    weekday: "long",
                    month: "long",
                  })}
                  <ChevronDownIcon />
                </Button>
              </PopoverTrigger>
              <PopoverContent tour-id="copypage-from-calendar">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>A partire dal</PopoverHeader>
                <PopoverBody>
                  <Calendar
                    minDate={new Date(new Date())}
                    onChange={(date: any) => {
                      setDateRange({ start: date, end: dateRange.end });
                      fromClose();
                    }}
                    value={dateRange.start}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </VStack>

          <Text fontSize="xs" as="b">
            -
          </Text>

          <VStack>
            <Popover isOpen={isToOpen} onOpen={toOpen} onClose={toClose}>
              <PopoverTrigger>
                <Button
                  size={"sm"}
                  variant={"outline"}
                  textTransform={"capitalize"}
                >
                  {dateRange?.end?.toLocaleDateString("it", {
                    day: "numeric",
                    weekday: "long",
                    month: "long",
                  })}
                  <ChevronDownIcon />
                </Button>

                {/* <Button variant="outline" leftIcon={<CalendarIcon />}>
                {dateRange.end.toLocaleDateString()}
              </Button> */}
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Fino al</PopoverHeader>
                <PopoverBody>
                  <Calendar
                    minDate={dateRange.start}
                    onChange={(date: any) => {
                      setDateRange({ end: date, start: dateRange.start });
                      toClose();
                    }}
                    value={dateRange.end}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </VStack>
        </HStack>

        <Text mt={6} mb={2} fontWeight={"bold"} fontSize={18}>
          Attenzione
        </Text>
        <Text textAlign={"center"}>
          Salvando, sovrascriverai tutti i{" "}
          <b style={{ textTransform: "capitalize" }}>
            {selectedDays.current.map((v: any) => week[v].dayName + ", ")}
          </b>{" "}
          del periodo indicato con gli orari da te impostati per i giorni
          selezionati.
        </Text>
      </Flex>

      <HStack
        width={"100%"}
        maxWidth={"1200px"}
        p={3}
        justifyContent="space-around"
        alignItems="space-around"
        bgColor={theme.colors.gray[100]}
      >
        <Button
          size={"lg"}
          ml={1}
          colorScheme="gray"
          onClick={() => {
            setPage(1);
          }}
          variant="outline"
          tour-id="copypage-cancel"
        >
          Annulla
        </Button>
        <Button
          size={"lg"}
          ml={1}
          colorScheme="brand"
          onClick={() => {
            let range = {
              from: formatDate(dateRange.start),
              to: formatDate(dateRange.end),
              week: {},
            };

            for (let d = 0; d < selectedDays.current.length; d++) {
              let dayIndex = selectedDays.current[d];
              let today = week[dayIndex as number]?.date
                ?.toLocaleDateString("en", {
                  weekday: "long",
                })
                .toLowerCase();

              (range.week as any)[today] = week[dayIndex as number].schedules;
            }

            //console.log(range);

            addRange({
              variables: { _ids: [_id], range },
            }).then(() => {
              getWeek();
            });
            setPage(1);
          }}
          variant="solid"
        >
          Salva
        </Button>
      </HStack>
    </>
  );
};

export { CopyPage };
