import React from "react";
import { Box, Hide, HStack, Text, VStack } from "@chakra-ui/react";

import { secure } from "stores/db/appstate";
import { CalendarDay, Resource } from "stores/queries/area";

type CalendarStatusProps = {
  today: CalendarDay;
  status: Resource["statuses"]["calendar"];
  calendarMeta?: Resource["calendarMetadata"];
  manualStatus: boolean;
  _id: string;
};

const CalendarStatus = (props: CalendarStatusProps) => {
  let openings = "";
  let dayStatus = null;
  if (props.today?.schedules?.length) {
    dayStatus = true;
    if (props.today.schedules[0] === "00:00-24:00") {
      openings = "tutto il giorno";
    } else {
      for (let i = 0; i < props.today.schedules.length; i++) {
        openings += props.today.schedules[i] + " ";
      }
    }
  }

  const status = props.status;

  const managed = typeof status?.status === "boolean";
  //const manualSetted = typeof props.manualStatus === "boolean";

  const today = new Date();

  let color = "gray";
  let txt = "Non Specificato";

  if (dayStatus) {
    color = "green";
    txt = "Aperto:";
  } else if (managed) {
    txt = "Chiuso";
    color = "pink";
  }

  return (
    <VStack w="100%" flex={1} pt={6} mb={6} borderTopWidth={0}>
      <HStack w="100%" alignItems="flex-start" flex={1}>
        {!secure() && (
          <Hide below="md">
            <Box height={"100%"}>
              <Text textAlign={"right"} width={"230px"} fontSize={"md"}>
                Oggi a calendario:
              </Text>
            </Box>

            <Box width={"2px"}></Box>
          </Hide>
        )}
        <HStack bg={"gray.100"} flex={1} borderRadius={20}>
          <Box
            justifyContent="center"
            alignItems="center"
            width={"60px"}
            bg={"gray.100"}
            borderRadius={10}
            overflow={"hidden"}
            display={"flex"}
            flexDirection={"column"}
            pt={1}
            borderWidth={1}
          >
            <Text fontWeight={"bold"}>{today.getDate()}</Text>

            <Box width={"100%"} bg={"brand.500"} py={1}>
              <Text
                fontSize={"sm"}
                fontWeight={"bold"}
                color={"white"}
                width={"100%"}
                textAlign={"center"}
                textTransform={"capitalize"}
              >
                {today.toLocaleString(undefined, {
                  month: "short",
                })}
              </Text>
            </Box>
          </Box>

          <VStack
            alignItems={"flex-start"}
            justifyContent={"center"}
            spacing={0}
          >
            <Text fontWeight={"bold"} textTransform={"capitalize"}>
              {today.toLocaleString(undefined, {
                weekday: "long",
              })}
            </Text>
            <Text color={color + ".500"}>
              {txt} {openings}
            </Text>
            {props.calendarMeta?.lastDay && (
              <Text color={"gray.500"} fontSize={"sm"}>
                {"Ultima data a calendario: " +
                  new Date(props.calendarMeta?.lastDay).toLocaleDateString()}
              </Text>
            )}
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  );
};

export { CalendarStatus };
