import pack from "../../../package.json";

const deviceInfo = {
  system: "WEB",
  app: {
    version: pack.version,
    name: "Mio Trentino Business",
  },
};

export { deviceInfo };
