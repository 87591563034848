import { LocalStorageWrapper } from "apollo3-cache-persist";
import localforage from "localforage";

function isSupported() {
  try {
    const testKey = "MowiLocalStorageTestKey";
    localforage.setItem(testKey, testKey);
    localforage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

let webStorage: any;

// controllo se localstorage supportato altrimenti fallback a ram
if (isSupported()) {
  webStorage = localforage;
} else {
  console.log("WARN: LOCALSTORAGE NOT AVAILABLE, USE INMEMORY STORAGE");
  webStorage = {
    _data: {},
    setItem: function (id: string, val: string) {
      return (this._data[id] = String(val));
    },
    getItem: function (id: string) {
      return Object.prototype.hasOwnProperty.call(this._data, id)
        ? this._data[id]
        : undefined;
    },
    removeItem: function (id: string) {
      return delete this._data[id];
    },
    clear: function () {
      return (this._data = {});
    },
  };
}

const cacheStorage = new LocalStorageWrapper(webStorage);
const dbStorage = webStorage;

const clear = () => {
  localforage.clear();
  webStorage.clear();
};

export { cacheStorage, dbStorage, clear };
