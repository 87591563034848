import React from "react";
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  Text,
  VStack,
  FormControl,
  Select,
} from "@chakra-ui/react";
import { renderThumb, renderTrack, renderView } from "../Scrollbar/Scrollbar";
import { IoMenuOutline } from "react-icons/io5";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";
import { NavRoute, routes } from "routes";
import SidebarBrand from "./components/Brand";
import { SideBarLink } from "./components/Links";
import { ACTUAL_AUTH } from "stores/db/auth";
import { ETgpArea } from "stores/queries/area";

const Sidebar = () => {
  const { pathname: pn } = useLocation();

  return (
    <Box display={{ sm: "none", xl: "block" }} position="fixed" minH="100%">
      <Box
        bg="white"
        transition="0.2s linear"
        w="285px"
        ms="16px"
        my="16px"
        h="calc(100vh - 32px)"
        borderRadius="24px"
        minH="100%"
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      >
        {/* <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        > */}
        <Flex
          direction="column"
          minH="100%"
          height="max-content"
          borderRadius="30px"
          overflow={"hidden"}
        >
          <SidebarBrand />
          <VStack w="100%" alignItems="start" gap="0px" mt={2.5}>
            {routes.map((route, index) => {
              return (
                <SideBarLink
                  key={"sidebarlink_" + index}
                  route={{
                    name: route.name,
                    path: route.path,
                    icon: route.icon,
                  }}
                />
              );
            })}
          </VStack>
        </Flex>
        {/* </Scrollbars> */}
      </Box>
    </Box>
  );
};

const SidebarResponsive = () => {
  const sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  const menuColor = useColorModeValue("gray.400", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="285px"
          maxW="285px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          borderRadius="16px"
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            color="white"
            zIndex="3"
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0" pt={0} borderRadius="16px">
            <SidebarBrand />

            {/*  <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
              style={{}}
            > */}
            <VStack w="100%" alignItems="start" gap="0px" mt={2.5}>
              {routes.map((route, index) => {
                return (
                  <SideBarLink
                    key={"sidebarlink_" + index}
                    route={{
                      name: route.name,
                      path: route.path,
                      icon: route.icon,
                    }}
                  />
                );
              })}
            </VStack>

            {/* </Scrollbars> */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export { Sidebar, SidebarResponsive };
