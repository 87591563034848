const formatDate = (d: Date) => {
  let ye = new Intl.DateTimeFormat("it", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("it", { month: "2-digit" }).format(d);
  let da = new Intl.DateTimeFormat("it", { day: "2-digit" }).format(d);
  return `${ye}-${mo}-${da}`;
};

function isBeforeToday(date: Date): boolean {
  const Today = new Date();
  Today.setDate(Today.getDate());
  Today.setHours(0, 0, 0, 0);
  return date < Today;
}

export { formatDate, isBeforeToday };
