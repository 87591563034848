import { jwtDecode } from "jwt-decode";

import { DEFAULT_ERROR, ENV, LOG } from "../../config";
import { scopes, user, area, skiarea } from "../../stores/db/appstate";
import { ACTUAL_AUTH, DB_AUTH } from "../../stores/db/auth";

type DecodedToken = {
  user: {
    _id: string;
  };
  auth: {
    isActive: boolean;
    isEmailVerified: boolean;
    permission: {
      scopes: string[];
    };
    lastLogin: string;
    area: string;
    skiArea: string;
  };
  iat: number;
  exp: number;
};

const log = LOG.extend("AUTHLIB");

const updateAuthState = async (tokens: {
  accessToken: string;
  refreshToken: string;
  atDecoded: DecodedToken;
}): Promise<boolean> => {
  await DB_AUTH.set({
    user: tokens?.atDecoded?.user?._id,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
    scopes: tokens?.atDecoded?.auth?.permission?.scopes,
    area: tokens?.atDecoded?.auth?.area,
    skiarea: tokens?.atDecoded?.auth?.skiArea,
  });

  log.debug("Set Auth state");

  scopes(tokens?.atDecoded?.auth?.permission?.scopes);
  area(tokens?.atDecoded?.auth?.area);
  skiarea(tokens?.atDecoded?.auth?.skiArea);
  user(tokens?.atDecoded?.user?._id);

  return true;
};

type FetchNewAccessToken = (
  refreshToken: string,
  area: string
) => Promise<
  { accessToken: string; refreshToken: string | undefined } | undefined
>;

// Funzione che controlla la validità di un Token
const isTokenValid = (token?: string | null): boolean => {
  if (!token) {
    return false;
  }
  const decodedToken: any = decodeToken(token);
  if (!decodedToken) {
    return false;
  }
  const now = new Date();
  return now.getTime() < decodedToken.exp * 1000;
};

// Funzione che decodifica un Token
const decodeToken = (token?: string | null) => {
  if (!token) {
    return undefined;
  }

  let decodedToken: { [key: string]: any };

  try {
    decodedToken = jwtDecode<{ [key: string]: any }>(token);
  } catch ({ message }: any) {
    log.error(`Decode Access Token ERROR: ${message}`);
    return undefined;
  }

  if (!decodedToken) {
    log.warn("Token decode error (=null) | " + token);
    return undefined;
  }

  return decodedToken as DecodedToken;
};

// Funzione che recupera un nuovo AccessToken dato il RefreshToken
const fetchNewAccessToken: FetchNewAccessToken = async (refreshToken, area) => {
  if (!ENV.auth.uri) {
    throw new Error("ENV.auth.uri must be set to use refresh token link");
  }
  try {
    const body = JSON.stringify({
      refreshToken,
      area,
    });

    const response = await fetch(ENV.auth.uri + "/refreshToken", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    });

    const refreshResponse = await response.json();

    if (
      !refreshResponse ||
      !refreshResponse.refreshToken ||
      !refreshResponse.accessToken
    ) {
      return undefined;
    }

    return {
      accessToken: refreshResponse.accessToken,
      refreshToken: refreshResponse.refreshToken,
    };
  } catch ({ message }: any) {
    log.error(`Fetch New Access Token ERROR: ${message}`);
    return undefined;
  }
};

const changeArea = async (newArea: string) => {
  if (!ENV.auth.uri) {
    throw new Error("ENV.auth.uri must be set to change area");
  }
  if (!ACTUAL_AUTH?.refreshToken) {
    throw new Error("ACTUAL_AUTH.refreshToken must be set to change area");
  }

  const res = await fetchNewAccessToken(ACTUAL_AUTH.refreshToken, newArea);

  const tokens = {
    accessToken: res.accessToken,
    refreshToken: res.refreshToken,
    atDecoded: decodeToken(res.accessToken),
  };

  if (!tokens?.atDecoded?.user?._id) {
    log.debug("Change area Faild, NO USER IN TOKEN");
    return {
      success: false,
      msg: DEFAULT_ERROR,
    };
  }

  await updateAuthState(tokens);

  return {
    success: true,
  };
};

// Funzione che gestisce la risposta ad un login, salva i nuovi token e setta gli stati
const handleLoginResponse = async (response: any) => {
  const loginResponse = await response.json();

  if (loginResponse?.errors[0]) {
    log.error(loginResponse.errors);
    return {
      success: false,
      msg: loginResponse?.errors[0],
    };
  }

  if (!loginResponse) {
    log.debug("Login Request Faild, NO RESPONSE");
    return {
      success: false,
      msg: DEFAULT_ERROR,
    };
  }

  if (!loginResponse.accessToken || !loginResponse.refreshToken) {
    if (loginResponse.success && !loginResponse.isEmailVerified) {
      log.debug("Login Success, NEED VERIFICATION");
      return {
        success: true,
        isEmailVerified: false,
        msg: "Login email code required",
      };
    } else {
      log.debug("Login Request Faild, NO TOKENS");
      return {
        success: false,
        msg: loginResponse?.errors[0] || DEFAULT_ERROR,
      };
    }
  }

  const tokens = {
    accessToken: loginResponse.accessToken,
    refreshToken: loginResponse.refreshToken,
    atDecoded: decodeToken(loginResponse.accessToken),
  };

  if (!tokens?.atDecoded?.user?._id) {
    log.debug("Login Request Faild, NO USER IN TOKEN");
    return {
      success: false,
      msg: loginResponse?.errors[0] || DEFAULT_ERROR,
    };
  }

  await updateAuthState(tokens);

  return {
    success: true,
    isEmailVerified: true,
    msg: "Login success",
  };
};

// Login user con email e password
const loginEmail = async (email: string) => {
  if (!ENV.auth.uri) {
    log.error("ENV.auth.uri must be set to login");
    throw new Error("ENV.auth.uri must be set to login");
  }

  if (!email) {
    log.debug("Login Request Faild, NO EMAIL");
    return { success: false, msg: DEFAULT_ERROR };
  }

  try {
    const body = JSON.stringify({
      email,
    });

    const response = await fetch(ENV.auth.uri + "/sendLoginCode", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    });

    const loginResponse = await handleLoginResponse(response);

    return loginResponse;
  } catch ({ message }: any) {
    log.error(`loginEmail ERROR: ${message}`);
    return {
      success: false,
      msg: DEFAULT_ERROR,
    };
  }
};

// funzione di login con codice email utente
const loginCode = async (email: string, code: string) => {
  if (!ENV.auth.uri) {
    log.error("ENV.auth.uri must be set to use refresh token link");
    throw new Error("ENV.auth.uri must be set to use refresh token link");
  }

  if (!email) {
    log.debug("Login Code Request Faild, NO email");
    return { success: false, msg: DEFAULT_ERROR };
  }

  if (!code) {
    log.debug("Login Code Request Faild, NO code");
    return { success: false, msg: "Codice verifica email obbligatorio!" };
  }

  try {
    const body = JSON.stringify({
      email,
      code,
    });

    const response = await fetch(ENV.auth.uri + "/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body,
    });

    const verifyResponse = await handleLoginResponse(response);

    return verifyResponse;
  } catch ({ message }: any) {
    log.error(`Verify Email ERROR: ${message}`);
    return {
      success: false,
      msg: DEFAULT_ERROR,
    };
  }
};

// Logout user e clear cache
const logout = async () => {
  log.debug("LOGOUT: clear auth user");
  await DB_AUTH.clearUser();
  log.debug("LOGOUT: reset state");
  user(null);
  log.debug("LOGOUT: clear cache");
  return true;
};

const checkScope = (scope: string) => {
  if (ACTUAL_AUTH?.scopes?.includes(scope)) {
    return true;
  } else {
    return false;
  }
};

export {
  isTokenValid,
  fetchNewAccessToken,
  decodeToken,
  loginEmail,
  loginCode,
  logout,
  checkScope,
  changeArea,
};

export type { DecodedToken };
