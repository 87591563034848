import React from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Heading,
  useMediaQuery,
  Skeleton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  useToast,
  IconButton,
} from "@chakra-ui/react";

import {
  CheckCircleIcon,
  CalendarIcon,
  ExternalLinkIcon,
  EditIcon,
} from "@chakra-ui/icons";

import {
  POI,
  POIS_CALENDAR_ADDRANGE_OPERATOR,
  POI_CALENDAR,
  POI_UPDATE,
} from "stores/queries/pois";
import { useParams } from "react-router-dom";
import { MowiIcon } from "ui/MowiIcon/Icon";
import { PoiStatus } from "components/Poi/PoiStatus";

import { PoiCalendar } from "components/Poi/PoiCalendar";
import { CalendarDay, Resource } from "stores/queries/area";
import { PoiOperatorLink } from "components/Poi/PoiOperatorLink";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import shepherdConfig from "./Tour/PoiSteps";
import { YearPage } from "components/Operator/YearPage";
import { secure } from "stores/db/appstate";

const YearTab = (props: { _id: string }) => {
  const [getCalendar, { loading: gcLoading }] = useLazyQuery<{
    adminPoiGetCalendarDays: CalendarDay[];
  }>(POI_CALENDAR, {
    context: { headers: { secureCode: `${secure()}` } },
    fetchPolicy: "network-only",
  });
  const getDays = async (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => {
    let days = null;

    try {
      const res = await getCalendar({
        variables: {
          from: data.variables.from,
          to: data.variables.to,
          _id: data.variables._id,
        },
      });
      days = res?.data?.adminPoiGetCalendarDays;
    } catch (error) {
      console.log(error);
    }

    return days;
  };

  const [addRange, { loading: aRLoading, error: arError, data: arData }] =
    useMutation(POIS_CALENDAR_ADDRANGE_OPERATOR, {
      context: { headers: { secureCode: `${secure()}` } },
    });
  return (
    <YearPage
      calendarMeta={{}}
      _id={props._id}
      addRange={addRange}
      getDays={getDays}
    />
  );
};

const PoiDetail = () => {
  let { _id } = useParams();

  const toast = useToast();
  const [isMobile] = useMediaQuery("(max-width: 600px)");
  const {
    isOpen: isEditingEmail,
    onOpen: onEditingEmail,
    onClose: offEditingEmail,
  } = useDisclosure();
  const [newEmail, setNewEmail] = React.useState<string>("");

  const { data, loading } = useQuery(POI, {
    variables: { _id },
    onCompleted: (data) => {
      if (newEmail == "" && !isEditingEmail)
        setNewEmail(data?.adminPoi?.email || "");
    },
  });
  const [triggerEmailChange] = useMutation(POI_UPDATE, {
    refetchQueries: [{ query: POI, variables: { _id } }],
  });

  const saveNewEmail = () => {
    triggerEmailChange({
      variables: {
        id: _id,
        poi: {
          email: newEmail,
        },
      },
    })
      .then(() => {
        toast({
          title: "Email aggiornata",
          description: "L'email è stata aggiornata correttamente",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        offEditingEmail();
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Errore",
          description:
            "Si è verificato un errore nell'aggiornamento dell'email",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };
  return (
    <Box w={"100%"} position={"relative"}>
      <Modal isOpen={isEditingEmail} onClose={offEditingEmail}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifica email</ModalHeader>
          <ModalBody>
            <Input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </ModalBody>
          <ModalFooter gap={3}>
            <Button variant={"outline"} onClick={offEditingEmail}>
              Chiudi
            </Button>
            <Button colorScheme="brand" onClick={saveNewEmail}>
              Salva
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <ShepherdTour tourOptions={shepherdConfig.tourOptions} steps={[]}>
        <StartTourButton />
      </ShepherdTour> */}
      {/* <Button onClick={() => tour.current?.start()}>Start tour</Button> */}
      <Tabs
        variant="soft-rounded"
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
        borderRadius={30}
        //defaultIndex={1}
        filter="auto"
        transition={"all"}
        isLazy
      >
        <Box
          flex={1}
          py={3}
          px={5}
          color={"white"}
          borderTopRadius={30}
          justifyContent={"space-between"}
          flexDirection="row"
          bg="tgpBlue"
        >
          <TabList
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <MowiIcon name="locator" size={35} />
            <Flex
              flexDirection={"column"}
              flex={1}
              alignItems={isMobile ? "center" : "flex-start"}
            >
              <Skeleton isLoaded={!loading && !!data}>
                <Heading noOfLines={1} size={isMobile ? "sm" : "md"} flex={1}>
                  {data?.adminPoi?.name?.find((n) => n.langIso === "it")
                    ?.value || ""}
                </Heading>
              </Skeleton>
              <Skeleton
                isLoaded={data?.adminPoi?.owner ? true : false}
                fadeDuration={2}
              >
                <Text noOfLines={1} size={isMobile ? "xs" : "sm"} flex={1}>
                  {data?.adminPoi?.owner}
                </Text>
                <Flex
                  id="email-edit"
                  alignItems={"center"}
                  cursor="pointer"
                  onClick={onEditingEmail}
                  py={"0.5"}
                  _hover={{
                    filter: "brightness(0.9)",
                  }}
                >
                  <Text noOfLines={1}>
                    {data?.adminPoi?.email || "Indirizzo email"}
                  </Text>
                  <EditIcon ml={2} />
                </Flex>
              </Skeleton>
            </Flex>
            <Flex flexDirection={"row"} mt={isMobile ? 5 : 0}>
              <Tab color={"white"} className="status-tab">
                {isMobile ? <CheckCircleIcon /> : "Stato"}
              </Tab>
              <Tab
                isDisabled={
                  data?.adminPoi?.statuses?.live?.updatedOrigin === "EXTERNAL"
                    ? true
                    : false
                }
                color={"white"}
                className="calendar-tab"
              >
                {isMobile ? <CalendarIcon /> : "Calendario"}
              </Tab>
              {/* <Tab isDisabled={true} color={"white"}>
                {isMobile ? <WarningTwoIcon /> : "Avvisi"}
              </Tab> */}
              {/* <Tab color={"white"}>
                {isMobile ? <CheckCircleIcon /> : "Anno"}
              </Tab> */}
              <Tab
                isDisabled={
                  data?.adminPoi?.statuses?.live?.updatedOrigin === "EXTERNAL"
                    ? true
                    : false
                }
                color={"white"}
                className="operator-tab"
              >
                {isMobile ? <ExternalLinkIcon /> : "Operatore"}
              </Tab>
            </Flex>
          </TabList>
        </Box>

        <TabPanels p={0} bg={"white"} borderBottomRadius={30}>
          <TabPanel>
            <PoiStatus _id={_id} />
          </TabPanel>
          <TabPanel>
            <PoiCalendar _id={_id} />
          </TabPanel>
          {/* <TabPanel>
            <WarningsList type="POI" _id={_id} />
          </TabPanel> */}
          {/* <TabPanel>
            <YearTab _id={_id} />
          </TabPanel> */}
          <TabPanel>
            <PoiOperatorLink _id={_id} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export { PoiDetail };

const StartTourButton = () => {
  const tour = React.useContext(ShepherdTourContext);
  const steps = React.useMemo(() => shepherdConfig.steps(tour), [tour]);
  tour.addSteps(steps);
  return (
    <IconButton
      aria-label="Help"
      pos="fixed"
      rounded="full"
      width={12}
      height={12}
      bg="brand.500"
      bottom={5}
      right={5}
      color="white"
      //shadow={"xl"}
      onClick={() => {
        tour.start();
      }}
      _hover={{
        filter: "brightness(0.9)",
      }}
      icon={
        <span
          style={{
            fontSize: "1.5rem",
          }}
        >
          ?
        </span>
      }
    />
  );
};
