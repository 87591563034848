import { HStack, Progress, Stack, Text } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import theme from "theme/theme";
import { MowiIcon } from "ui/MowiIcon/Icon";

type ManagedProgressProps = {
  series: {
    admin: number;
    operator: number;
    adobe: number;
  };
  total: number;
};

const ManagedProgress = (props: ManagedProgressProps) => {
  return (
    <Stack spacing={5} flex={1} borderRightWidth={2} paddingRight={5}>
      <HStack>
        <Text textAlign={"end"} width={100}>
          MTB A.P.T.
        </Text>
        <Progress
          width={"100%"}
          colorScheme="yellow"
          size="md"
          value={props.total ? (props.series.admin / props.total) * 100 : 0}
        />
        <Text textAlign={"right"} width={"80px"}>
          {props.series.admin}/{props.total}
        </Text>
      </HStack>
      <HStack>
        <Text textAlign={"end"} width={100}>
          MTB Operatore
        </Text>
        <Progress
          width={"100%"}
          colorScheme="cyan"
          size="md"
          value={props.total ? (props.series.operator / props.total) * 100 : 0}
        />
        <Text textAlign={"right"} width={"80px"}>
          {props.series.operator}/{props.total}
        </Text>
      </HStack>
      <HStack>
        <Text textAlign={"end"} width={100}>
          Adobe
        </Text>
        <Progress
          width={"100%"}
          colorScheme="gray"
          size="md"
          value={props.total ? (props.series.adobe / props.total) * 100 : 0}
        />
        <Text textAlign={"right"} width={"80px"}>
          {props.series.adobe}/{props.total}
        </Text>
      </HStack>
      {/* <HStack width={"100%"} justifyContent={"center"} alignItems={"center"}>
        <MowiIcon
          size={22}
          name={"point_warning"}
          color={theme.colors.gray[500]}
        />
        <Text color={"gray.700"}>Nessun avviso attivo</Text>
      </HStack> */}
    </Stack>
  );
};

export { ManagedProgress };
