import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  PinInput,
  PinInputField,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Assets
import { FcGoogle } from "react-icons/fc";
import { loginCode, loginEmail } from "libs/apollo/auth";
import { DEFAULT_ERROR } from "config";

const Login = () => {
  const [error, setError] = useState<null | string>(null);
  const [form, setForm] = useState<{ code: string; email: string }>({
    code: "",
    email: "",
  });
  const [existingUser, setExistingUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isButtonDisabled = () => {
    if (isLoading) return true;
    if (!existingUser && form?.email?.length < 3) return true;
    if (existingUser && form?.code?.length < 5) return true;
    return false;
  };

  const isDisabled = isButtonDisabled();

  const login = () => {
    if (isDisabled) return;
    setIsLoading(true);
    setError(null);

    if (form.email?.length > 3 && existingUser && form.code?.length == 5) {
      loginCode(form.email, form.code).then((res) => {
        console.log("LOGINCODE:", res);
        if (res.success) {
          //setExistingUser(true);
        } else {
          setExistingUser(false);
          setError(res.msg || DEFAULT_ERROR);
        }
        setIsLoading(false);
      });
    } else if (form.email?.length > 3) {
      loginEmail(form.email).then((res) => {
        console.log("LOGINEMAIL:", res);
        if (res.success && !res.isEmailVerified) {
          setExistingUser(true);
        } else {
          setError(res.msg || DEFAULT_ERROR);
        }
        setIsLoading(false);
      });
    }
  };

  const getFormFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const code = urlParams.get("code");
    if (email && code) {
      setIsLoading(true);
      loginCode(email, code)
        .then((res) => {
          console.log("LOGINCODE:", res);
          if (!res.success) {
            setError(res.msg || DEFAULT_ERROR);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (email == "" || code == "") setError("Link non valido");
    }
  };

  useEffect(() => {
    getFormFromUrl();

    const enterKeyHandler = (event: any) => {
      if (event.key === "Enter" || event.code === "Enter") {
        login();
      }
    };
    window.addEventListener("keyup", enterKeyHandler);

    return () => {
      window.removeEventListener("keyup", enterKeyHandler);
    };
  }, []);

  return (
    <Box
      flex={1}
      zIndex={10}
      maxW={{ base: "100%", md: "max-content" }}
      w={{ base: "98%", lg: "100%" }}
      mx={{ base: "2%", lg: "0px" }}
      h="100%"
      bgColor={"#fff"}
      p={10}
      borderRadius={20}
      shadow={"xl"}
      alignItems="center"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      mt={{ base: "40px", md: "14vh" }}
      flexDirection="column"
    >
      <Box me="auto">
        <Heading
          textAlign={{ base: "center", md: "left" }}
          color={"gray.600"}
          fontSize="30px"
          mb="0px"
        >
          Mio Trentino Business
        </Heading>
        {/*         <Text
          textAlign={{ base: "center", md: "left" }}
          color={"gray.500"}
          fontWeight="600"
          fontSize="md"
        >
          Business
        </Text> */}
      </Box>
      <Flex h="1px" w="100%" bg="rgba(135, 140, 189, 0.1)" my={5} />

      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form>
          <FormControl>
            {form.email && existingUser ? (
              <>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={"navy.700"}
                  mb="8px"
                  textAlign={"center"}
                  width={"100%"}
                >
                  Inserisci il codice di verifica di 5 cifre ricevuto via email
                </FormLabel>
                <HStack
                  mb="24px"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <PinInput
                    onChange={(val) => {
                      setForm({ ...form, code: val });
                    }}
                    otp
                    size="lg"
                  >
                    <PinInputField autoFocus={true} />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </>
            ) : (
              <>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={"navy.700"}
                  mb="8px"
                >
                  Email<Text color={"brand.600"}>*</Text>
                </FormLabel>
                <Input
                  name="email"
                  id="email"
                  isRequired={true}
                  variant="auth"
                  colorScheme={"brand"}
                  focusBorderColor="brand.500"
                  fontSize="sm"
                  type="email"
                  placeholder="La tua email"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  value={form.email}
                  onChange={(event) => {
                    setForm({ ...form, email: event.target.value });
                  }}
                />
              </>
            )}

            {error ? (
              <Box w="100%" p={4} pt={0} color="tomato" textAlign={"center"}>
                {error}
              </Box>
            ) : null}

            <Button
              type="submit"
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={(e) => {
                e.preventDefault();
                login();
              }}
              isLoading={isLoading}
              isDisabled={isDisabled ? true : false}
              _hover={{ bg: "brand.500" }}
            >
              {form?.email?.length && existingUser ? "Verifica" : "Accedi"}
            </Button>
          </FormControl>
        </form>
        {/* <Flex mb="24px">
          <NavLink to="/auth/forgot-password">
            <Text color={"brand.600"} fontSize="sm" w="124px" fontWeight="500">
              Email dimenticata?
            </Text>
          </NavLink>
        </Flex> */}
      </Flex>
    </Box>
  );
};

export { Login };
