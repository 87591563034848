import { HStack, Progress, Stack, Text } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import theme from "theme/theme";
import { MowiIcon } from "ui/MowiIcon/Icon";

type OpeningProgressProps = {
  series: number[];
  total: number;
};

const OpeningProgress = (props: OpeningProgressProps) => {
  return (
    <Stack spacing={5} flex={1} borderRightWidth={2} paddingRight={5}>
      <HStack>
        <Text textAlign={"end"} width={100}>
          Aperti
        </Text>
        <Progress
          width={"100%"}
          colorScheme="green"
          size="md"
          value={props.total ? (props.series[0] / props.total) * 100 : 0}
        />
        <Text textAlign={"right"} width={"80px"}>
          {props.series[0]}/{props.total}
        </Text>
      </HStack>
      <HStack>
        <Text textAlign={"end"} width={100}>
          Chiusi
        </Text>
        <Progress
          width={"100%"}
          colorScheme="pink"
          size="md"
          value={props.total ? (props.series[1] / props.total) * 100 : 0}
        />
        <Text textAlign={"right"} width={"80px"}>
          {props.series[1]}/{props.total}
        </Text>
      </HStack>
      <HStack>
        <Text textAlign={"end"} width={100}>
          N.D.
        </Text>
        <Progress
          width={"100%"}
          colorScheme="gray"
          size="md"
          value={props.total ? (props.series[2] / props.total) * 100 : 0}
        />
        <Text textAlign={"right"} width={"80px"}>
          {props.series[2]}/{props.total}
        </Text>
      </HStack>
      {/* <HStack width={"100%"} justifyContent={"center"} alignItems={"center"}>
        <MowiIcon
          size={22}
          name={"point_warning"}
          color={theme.colors.gray[500]}
        />
        <Text color={"gray.700"}>Nessun avviso attivo</Text>
      </HStack> */}
    </Stack>
  );
};

export { OpeningProgress };
