import { useMutation, useQuery } from "@apollo/client";
import { Box, Divider, Flex, Skeleton, Text } from "@chakra-ui/react";
import { CalendarStatus } from "components/Status/CalendarStatus";
import { LiveStatus } from "components/Status/LiveStatus";
import { ManualStatus } from "components/Status/ManualStatus";
import { formatDate } from "libs/helpers";
import { secure } from "stores/db/appstate";
import { CalendarDay, Resource } from "stores/queries/area";

import {
  TRACK,
  TRACKS_STATUS_UPDATE,
  TRACK_CALENDAR,
} from "stores/queries/tracks";

type TrackStatusProps = {
  _id: string;
};

const TrackStatus = (props: TrackStatusProps) => {
  const { loading: cLoading, data: cData } = useQuery<{
    adminTrackGetCalendarDays: CalendarDay[];
  }>(TRACK_CALENDAR, {
    variables: {
      from: formatDate(new Date()),
      to: formatDate(new Date()),
      _id: props._id,
    },
    context: secure() ? { headers: { secureCode: `${secure()}` } } : undefined,
    fetchPolicy: "network-only",
  });

  const {
    loading: rLoading,
    data: rData,
    refetch,
  } = useQuery<{ adminTrack: Resource }>(TRACK, {
    variables: { _id: props._id },
    fetchPolicy: "no-cache",
    context: secure() ? { headers: { secureCode: `${secure()}` } } : undefined,
    pollInterval: 12000,
  });

  const [updateStatus, { loading: usLoading }] = useMutation<{
    adminTracksUpdateStatus: Resource[];
  }>(TRACKS_STATUS_UPDATE, {
    context: secure() ? { headers: { secureCode: `${secure()}` } } : undefined,
    refetchQueries: [TRACK],
    awaitRefetchQueries: true,
  });

  return (
    <Box flex={1} position={"relative"}>
      <Skeleton isLoaded={!rLoading}>
        <LiveStatus
          _id={props._id}
          manual={rData?.adminTrack?.statuses?.manual}
          live={rData?.adminTrack?.statuses?.live}
        />
      </Skeleton>

      {rData?.adminTrack?.statuses?.live?.updatedOrigin !== "EXTERNAL" ? (
        <>
          <Skeleton isLoaded={!cLoading && !rLoading}>
            <CalendarStatus
              today={cData?.adminTrackGetCalendarDays[0]}
              status={rData?.adminTrack?.statuses?.calendar}
              manualStatus={rData?.adminTrack?.statuses?.manual?.status}
              _id={props._id}
            />
          </Skeleton>
          <Divider />
          <Skeleton isLoaded={!rLoading}>
            <Flex direction={["column", "row"]} width={"100%"}>
              <ManualStatus
                updateStatus={updateStatus}
                onUpdated={() => {
                  refetch();
                }}
                _id={props._id}
                manual={rData?.adminTrack?.statuses?.manual}
                live={rData?.adminTrack?.statuses?.live}
              />
            </Flex>
          </Skeleton>
        </>
      ) : (
        <Skeleton isLoaded={!rLoading}>
          <Flex direction={["column", "row"]} width={"100%"}>
            {!secure() && <Box width={"230px"}></Box>}
            <Text flex={1} textAlign={"center"}>
              Per modificare lo stato devi agire sulla fonte esterna
            </Text>
          </Flex>
        </Skeleton>
      )}
    </Box>
  );
};

export { TrackStatus };
