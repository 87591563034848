import React from "react";
import { TrackOperator } from "components/Track/TrackOperator";
import { Text } from "@chakra-ui/react";
import { secure } from "stores/db/appstate";
import { PoiOperator } from "components/Poi/PoiOperator";
import { TransportOperator } from "components/Transport/TransportOperator";
import { PoiTableInfos } from "components/InfoPoint/PoiTableInfos";
import { PoiCalendarInfos } from "components/InfoPoint/PoiCalendarInfos";

const PoisInfos = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let area = urlParams.get("a");
  let _id = urlParams.get("_id");

  if (_id) {
    return <PoiCalendarInfos _id={_id} area={area} />;
  }

  return <PoiTableInfos area={area} />;
};

export { PoisInfos };
