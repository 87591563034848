import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";

import { Box, Button, useDisclosure } from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import styled from "@emotion/styled";
import Card from "ui/Card";
import itLocale from "@fullcalendar/core/locales/it";
import theme from "theme/theme";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CalendarDay, Resource } from "stores/queries/area";
import { Loading } from "ui/Loading/Loading";
import { AddRangeDrawer } from "./AddRangeDrawer";
import { formatDate, isBeforeToday } from "libs/helpers";
import { DatesSetArg, EventInput } from "@fullcalendar/core";
import { AddRangeMutation, ResetCalendarMutation } from "components/Poi/types";

export const StyleWrapper = styled.div`
  .fc-toolbar-title {
    text-transform: capitalize;
    font-weight: 500;
  }
  .fc-scrollgrid {
    border-color: transparent !important;
  }
  .fc-scrollgrid td:last-of-type {
    border-right-color: transparent !important;
  }
  .fc-scrollgrid-section.fc-scrollgrid-section-body td[role="presentation"] {
    border-bottom-color: transparent !important;
  }
  [role="row"]:last-of-type td {
    border-bottom-color: transparent !important;
  }
  th[role="presentation"] {
    border-right-color: transparent !important;
  }

  .fc-view-harness {
    border-width: 1px;
    border-radius: 20px !important;
    overflow: hidden;
  }

  .fc-button.fc-prev-button,
  .fc-button.fc-next-button,
  .fc-button.fc-button-primary {
    background-image: none;
    border-color: ${theme.colors.tgpBlue};
    background-color: ${theme.colors.tgpBlue};
    border-radius: 10px;
    border-color: #fff !important;
    border-width: 0px;
  }
  .fc-event-title {
    color: #fff;
    font-weight: bold !important;
  }
  .fc .fc-bg-event {
    opacity: 0.8 !important;
  }
  .fc .fc-daygrid-day {
    background-color: ${theme.colors.green[50]} !important;
    border-color: ${theme.colors.gray[400]} !important;
    border-width: 0.5px !important;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${theme.colors.brand[400]} !important;
    border-color: ${theme.colors.white[500]} !important;
  }
  .fc-daygrid-day-top {
    color: ${theme.colors.gray[100]} !important;
    opacity: 1 !important;
  }
  .fc .fc-highlight {
    --fc-highlight-color: #f6faa882;
  }
  @keyframes breathe {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 1;
    }
  }
  .breathe {
    animation: breathe 1.2s ease-in-out infinite alternate;
  }
  .breathe:hover {
    animation: none;
  }
  .breathe::before {
    content: "Chiuso manualmente";
    transition: all 0.2s ease-in-out;
    font-size: 15px;
    font-weight: medium;
    visibility: hidden;
    position: absolute;
    bottom: 28px;
    left: 0;
    width: 100%;
    white-space: wrap;
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 15;
    opacity: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .breathe:hover::before {
    visibility: visible;
    opacity: 1;
  }
`;

const isFullDay = (schedule: string) => schedule === "00:00-24:00";

const getEventInput = (calendarDay: CalendarDay) => {
  const day = calendarDay.day;
  const tmpEvents: EventInput[] = [];
  let before = false;
  if (isBeforeToday(new Date(day))) {
    before = true;
    //continue;
  }

  /* if (data[i].status === true) {
            const schedules = data[i]?.schedules || [];
            if (!schedules) continue;
            for (let j = 0; j < schedules.length; j++) {
              const schedule = schedules[j];
              let newEvent = {
                title: schedule,
                start: day,
                end: day,
                backgroundColor: theme.colors.green[500],
                borderColor: theme.colors.green[500],
              };
              events.push(newEvent);
            }
          } else if (data[i].status === false) {
            let newEvent = {
              title: "CLOSE",
              start: day,
              end: day,
              backgroundColor: theme.colors.red[500],
              borderColor: theme.colors.red[500],
            };
            events.push(newEvent);
          } */
  const schedules = calendarDay.schedules;
  // Se ci sono orari impostati
  if (schedules?.length) {
    let newEvent = {
      //allDay: schedule === "00:00-24:00" ? true : false,
      display: "background",
      title: "APERTO",
      start: day,
      end: day,
      backgroundColor: before
        ? theme.colors.green[200]
        : theme.colors.green[400],
      borderColor: before ? theme.colors.green[200] : theme.colors.green[400],
      textColor: before ? "#ddd" : "#fff",
    };
    tmpEvents.push(newEvent);
    for (let j = 0; j < schedules.length; j++) {
      const schedule = schedules[j];
      let newEvent = {
        //allDay: schedule === "00:00-24:00" ? true : false,
        display: isFullDay(schedule) ? "background" : "block",
        title: isFullDay(schedule) ? "APERTO" : schedule,
        start: day,
        end: day,
        backgroundColor: before
          ? theme.colors.green[200]
          : theme.colors.green[500],
        borderColor: before ? theme.colors.green[200] : theme.colors.green[500],
        textColor: before ? "#ddd" : "#fff",
      };
      tmpEvents.push(newEvent);
    }
    // Se non ci sono orari impostati
  } else if (schedules && schedules.length === 0) {
    let newEvent = {
      allDay: true,
      display: "background",
      title: "CHIUSO",
      start: day,
      end: day,
      backgroundColor: before ? theme.colors.pink[200] : theme.colors.pink[400],
      borderColor: before ? theme.colors.pink[200] : theme.colors.pink[400],
      textColor: before ? "#666" : "#000",
    };
    tmpEvents.push(newEvent);
    // Se non ci sono informazioni
  } else {
    let newEvent = {
      allDay: true,
      display: "background",
      title: "NON SPECIFICATO",
      start: day,
      end: day,
      backgroundColor: before ? theme.colors.gray[200] : theme.colors.gray[300],
      borderColor: before ? theme.colors.gray[200] : theme.colors.gray[400],
      color: "#000 !important",
    };
    tmpEvents.push(newEvent);
  }
  return tmpEvents;
};

type OpeningCalendarProps = {
  _id: string;
  getDays: (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => Promise<CalendarDay[]>;
  addRange?: AddRangeMutation;
  resetCalendar?: ResetCalendarMutation;
  loading: boolean;
  manualStatus?: Resource["statuses"]["manual"];
};

const OpeningCalendar = (props: OpeningCalendarProps) => {
  const [calDates, setCalDates] = useState<DatesSetArg | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [calendarSelection, setCalendarSelection] = useState<{
    start: Date;
    end: Date;
  } | null>(null);

  useEffect(() => {
    getCalendar();
  }, [calDates, props.manualStatus]);

  // useEffect(() => {
  //   getCalendar();
  // }, []);

  const [events, setEvents] = useState<EventInput[]>([]);

  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure();

  const getCalendar = async () => {
    if (!calDates || !props._id) return;
    const data = await props.getDays({
      variables: {
        from: formatDate(calDates.start),
        to: formatDate(calDates.end),
        _id: props._id,
      },
    });
    if (!data) {
      return;
    }
    const newEvents: EventInput[] = [];
    for (let i = 0; i < data.length; i++) {
      newEvents.push(...getEventInput(data[i]));
    }
    // const manual = {
    //   status: false,
    //   lastUpdate: "",
    //   motivation: [
    //     {
    //       langIso: "",
    //       value: "",
    //     },
    //   ],
    //   expiration: "2023-08-30T17:30:00.000Z",
    // };
    const manual = props.manualStatus;
    if (manual && manual.status === false) {
      const today = new Date().toISOString().slice(0, 10);
      const until = new Date(manual.expiration);
      const endTime = until.toTimeString().slice(0, 5);
      newEvents.push({
        display: "block",
        title: "Chiuso (Man) - " + endTime,
        date: today,
        backgroundColor: theme.colors.red[500],
        borderColor: theme.colors.red[500],
        className: "breathe",
      });
    }
    setEvents(newEvents);
  };

  return (
    <Box flexDirection="column" position={"relative"} width={"100%"}>
      <Card p={0}>
        <StyleWrapper>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            selectable={props.addRange ? true : false}
            select={(info) => {
              if (!props.addRange) return;
              const dayBeforeInfoEnd = new Date(info.end);
              dayBeforeInfoEnd.setDate(dayBeforeInfoEnd.getDate() - 1);
              info.end = dayBeforeInfoEnd;
              let isBefore = isBeforeToday(info.start);
              if (!isBefore) {
                setCalendarSelection(info);
                setDrawerOpen(true);
              }
            }}
            initialView="dayGridMonth"
            events={events}
            locale={itLocale}
            customButtons={{
              editRange: props.addRange
                ? {
                    text: "Modifica Periodo",
                    click: function () {
                      setDrawerOpen(true);
                    },
                  }
                : null,
              reset: props.resetCalendar
                ? {
                    text: "Reset",
                    click: function () {
                      onOpenReset();
                    },
                  }
                : null,
            }}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: props.addRange ? "reset editRange" : "",
            }}
            fixedWeekCount={false}
            contentHeight={500}
            datesSet={(data) => {
              setCalDates(data);
            }}
            /* eventClick={(info) => {
              if (!isBeforeToday(info.event.start)) {
                let dayN =
                  info.event.start.getDay() === 0
                    ? 7
                    : info.event.start.getDay();
                setSelectedIndexDay(dayN);
                setSelectedWeek(startOfWeek(info.event.start, { locale: it }));

                //setSelectedDay(info.event.start);
                dayOpen();
              }
            }}
            dateClick={(info) => {
              if (!isBeforeToday(info.date)) {
                let dayN = info.date.getDay() === 0 ? 7 : info.date.getDay();
                setSelectedIndexDay(dayN);
                setSelectedWeek(startOfWeek(info.date, { locale: it }));

                dayOpen();
              }
            }} */
          />
        </StyleWrapper>
      </Card>

      <Modal isOpen={isOpenReset} onClose={onCloseReset}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset calendario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Confermando cancellerai l'intero calendario impostato, sei sicuro di
            voler continuare?
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseReset}>
              Annulla
            </Button>
            <Button
              onClick={() => {
                props
                  .resetCalendar({ variables: { _id: props._id } })
                  .then(() => {
                    getCalendar();
                  });
                onCloseReset();
              }}
              colorScheme="red"
            >
              Si
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddRangeDrawer
        _ids={[props._id]}
        addRange={props.addRange}
        refresh={getCalendar}
        open={drawerOpen}
        onClosed={() => {
          setDrawerOpen(false);
          setCalendarSelection(null);
        }}
        calendarSelection={calendarSelection}
      />
      {props.loading ? <Loading /> : null}
    </Box>
  );
};

export { OpeningCalendar };
